.co-m-nav-title {
  padding: $grid-gutter-width ($grid-gutter-width / 2) 0;
  @media (min-width: $grid-float-breakpoint) {
    padding-left: $grid-gutter-width;
    padding-right: $grid-gutter-width;
  }
  &--breadcrumbs {
    padding-top: 0;
  }
  &--detail {
    border-bottom: 1px solid $color-grey-background-border;
    background-color: $color-grey-background;
  }
  &--logo {
    padding-top: ($grid-gutter-width / 2);
  }
}
