$kinds: (
  configmap: $color-configmap-dark,
  daemonset: $color-replicaset-dark,
  deployment: $color-deployment-dark,
  deploymentconfig: $color-deploymentconfig-dark,
  ingress: $color-ingress-dark,
  job: $color-job-dark,
  namespace: $color-namespace-dark,
  node: $color-node-dark,
  pod: $color-pod-dark,
  project: $color-namespace-dark,
  replicaset: $color-replicaset-dark,
  replicationcontroller: $color-controller-dark,
  secret: $color-secret-dark,
  service: $color-service-dark,
  serviceaccount: $color-serviceaccount-dark,
  servicemonitor: $color-service-dark,
  alertmanager: $color-alertmanager-dark,
);

@each $kind, $color in $kinds {
  .co-text-#{$kind} {
    color: $color;

    &:link, &:visited, &:hover, &:active {
      color: $color;
      text-decoration: none;
    }
  }
}
