.co-toggle-play {
  background-color: #fff;
  border: 3px solid $color-grey-border;
  border-radius: 50%;
  height: 32px;
  margin-right: 15px;
  padding: 0;
  width: 32px;

  // play icon
  &.co-toggle-play--inactive:before {
    content: $fa-var-play;
  }
  // pause icon
  &.co-toggle-play--active:before {
    content: $fa-var-pause;
  }

  &.co-toggle-play--active {
    border-color: $color-tectonic-green;
  }
}
