.service-ips {
  .co-ip-header {
    padding: 10px 0 5px 20px;
  }

  .co-ip-row {
    // Collapse borders when multiple consecutive rows.
    margin-top: -1px;
    padding: 20px 0 5px 20px;
    border: 1px solid #eee;

    .ip-name {
      margin-bottom: 5px;
    }
  }
}
