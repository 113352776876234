$clusterserviceversion-list-border: #ccc;

.co-clusterserviceversion-list {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -7.5px 0;
}

.co-clusterserviceversion-list__tile {
  padding: 0 7.5px 15px;
  width: 100%;
  @media (min-width: $screen-sm) {
    width: 50%;
  }
  @media (min-width: $screen-md) {
    width: 33.333%;
  }
  @media (min-width: 1920px) {
    width: 25%;
  }
}

.co-clusterserviceversion-list-item {
  border: 1px solid $clusterserviceversion-list-border;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 20px;
  height: 200px;
}

.co-clusterserviceversion-list-item--error {
  border: 1px solid #dd1326;
}

.co-clusterserviceversion-logo {
  display: flex;
}

.co-clusterserviceversion-logo__icon {
  background: #fff;
  width: 60px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.co-m-nav-title--logo .co-clusterserviceversion-logo__icon {
  border: 1px solid #e5e5e5;
}

.co-clusterserviceversion-logo__name {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 10px;
}

.co-clusterserviceversion-logo__name__clusterserviceversion {
  font-size: 15px;
  margin-top: 0;
  margin-bottom: 5px;
}

.co-clusterserviceversion-logo__name__provider {
  font-size: 13px;
}

.co-clusterserviceversion-list-item__actions {
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.co-clusterserviceversion-list-item__description {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding: 10px 0;
}

.co-clusterserviceversion-resource-details__compact-expand {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 20px;
}

.co-clusterserviceversion-resource-details__section--info dd {
  margin-bottom: 20px;
}

.co-clusterserviceversion-details {
  display: flex;
  flex-wrap: wrap;
}

.co-clusterserviceversion-details__section--info {
  display: flex;
  flex-direction: column;
  @media (min-width: 768px) {
    width: 33%;
  }
  @media (min-width: 1024px) {
    width: 25%;
  }
  @media (min-width: 1280px) {
    width: 20%;
  }
  @media (min-width: 1440px) {
    width: 20%;
  }
  @media (min-width: 1920px) {
    width: 20%;
  }
}

.co-clusterserviceversion-details__section--info__item {
  margin-bottom: 15px;
}

.co-clusterserviceversion-details__section--description {
  display: flex;
  flex-direction: column;
  @media (min-width: 768px) {
    width: 66%;
  }
  @media (min-width: 1024px) {
    width: 75%;
  }
  @media (min-width: 1280px) {
    width: 80%;
  }
  @media (min-width: 1440px) {
    width: 80%;
  }
  @media (min-width: 1920px) {
    width: 80%;
  }
}

.co-clusterserviceversion-detail__error-box {
  padding: 10px;
  border: 1px solid #dd1326;
  background: #ffe6e6;
  color: #dd1326;
  font-size: 14px;
  margin-bottom: 10px;
}

.co-catalog-install-modal .modal-header .co-clusterserviceversion-logo__icon {
  border: 1px solid #ddd;
}

.co-catalog-spinner--downloading {
  color: $color-highlight-blue;
}

.co-catalog-details {
  display: flex;
  flex-direction: column;
}

.co-package-row {
  display: flex;
  align-items: center;
}

.co-package-row__actions {
  display: flex;
  justify-content: space-between;
}

.co-catalogsource-list__section {
  margin-bottom: 40px;
}

.co-catalogsource-list__section__packages {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 20px;
}

.co-clusterserviceversion-list__disabled-icon {
  margin: 10px;
  width: 100px;
  height: 100px;
  filter: grayscale(100%);
}

.co-spec-descriptor--resource-requirements {
  border: 1px solid #eee;
  padding: 20px 0 5px 20px;
  margin-top: 10px;
}

.step--present {
  color: $color-pf-green-400;

}
