.co-masthead {
  align-items: center;
  background-color: $color-pf-black;
  color: $color-os-nav-title;
  display: flex;
  height: $masthead-height-mobile;
  left: 0;
  position: fixed;
  right: 0;
  z-index: $masthead-z-index;
  @media (max-width: $grid-float-breakpoint-max) {
    padding-left: 50px; // make space for .sidebar-toggle
  }
  @media(min-width: $grid-float-breakpoint) {
    height: $masthead-height-desktop;
  }
  &__console-picker {
    margin-left: 10px;
    @media(max-width: $grid-float-breakpoint-max) {
      display: none;
    }
  }
  &__logo {
    align-items: center;
    display: flex;
    height: 100%;
    @media (min-width: $grid-float-breakpoint) {
      margin-left: 20px;
      margin-right: 10px;
    }
    img {
      max-width: 100%;
    }
  }
  &__logo-link {
    display: flex;
    height: 100%;
  }
  &__user {
    display: none;
    @media (min-width: $grid-float-breakpoint) {
      display: block;
      margin-left: auto;
      margin-right: 20px;
    }
    .dropdown__not-btn,
    .dropdown__not-btn__title {
      align-items: baseline;
      display: flex;
    }
  }
  &__user-icon {
    font-size: 14px;
    margin-right: 5px;
  }
  &__username {
    font-weight: 300;
    // truncate very long usernames
    max-width: 200px;
    overflow: hidden;
    text-overflow: ellipsis;
    @media(min-width: $screen-md-min) {
      max-width: 300px;
    }
  }
}

.contextselector-pf .bootstrap-select > .dropdown-toggle {
  font-weight: 300;
  padding-top: 19px;
  width: 100%;
  &:focus {
    outline: 0 !important;
  }
}

.contextselector-pf .bootstrap-select > .dropdown-menu {
  min-width: 100%;
}
