.co-documentation-links__title {
  font-size: 14px;
  font-weight: 600;
  text-transform: none;
}

.co-documentation-links__description {
  margin-top: 8px;
}

.co-additional-support-links__link {
  font-size: 14px;
  font-weight: 600;
}

.co-additional-support-links__link-icon {
  margin-right: 7px;
}
