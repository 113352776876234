.co-overlay {
  background: rgba(0, 0, 0, 0.5);
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: $zindex-modal;
}

.ReactModal__Content {
  // close icon
  .fa-times-circle:hover {
    cursor: pointer;
    color: $black;
  }
}

.ReactModal__Content--after-open {
  -webkit-transform: translate(0, 0);
  -ms-transform: translate(0, 0);
  -o-transform: translate(0, 0);
  transform: translate(0, 0);
}

.tags-list {
  &__row {
    padding-bottom: 15px;
  }

  &__field {
    padding-right: 0;
  }

  &__heading {
    margin-bottom: 4px;
    text-transform: uppercase;
    color: $color-text-secondary;
  }

  &__btn {
    cursor: pointer;
  }

  &__delete-icon {
    line-height: 32px;
  }

  &__add-icon {
    margin-right: 6px;
  }
}
