.group {
  margin-bottom: ($grid-gutter-width / 2);
}

.group__title {
  border: 1px solid $color-grey-background-border;
  background-color: $color-grey-background;
  display: flex;
  justify-content: space-between;
  padding: 10px 15px;
  @media(max-width: $screen-xs) {
    flex-direction: column;
  }
  h2 {
    margin: 0;
  }
}

.group__body {
  border: 1px solid $color-grey-background-border;
  border-top: none;
}

// Maintain uniform spacing above filter-bar and
// allow group__body borders to connect with group__title bar
.group__body--filter-bar {
  margin-top: -$grid-gutter-width;
  padding-top: $grid-gutter-width;
}

.group__documentation {
  padding-bottom: 12px;
  padding-top: 12px;
}

.group__additional-support {
  padding-top: 12px;
}

.group__graphs {
  overflow: hidden;
}

.cluster-overview-cell {
  margin-top: -($grid-gutter-width / 2);
  @media(min-width: $screen-lg-min) {
    .col-lg-8 {
      padding-right: 0;
    }
  }
}

.cluster-overview-cell__title {
  font-size: 16px;
  margin-top: 0;
  margin-bottom: 18px;
  line-height: 25px;
}

.cluster-overview-cell__info-row {
  border-bottom: solid 1px $color-grey-background-border;
  padding: 12px 0;
  line-height: 20px;
  &:last-child {
    border-bottom: none;
  }
}

.cluster-overview-cell__info-row__first-cell {
  margin-left: -5px;
}

.cluster-overview-cell__info-row__last-cell {
  margin-right: -5px;
  text-align: right;
}
