/* COLORS */
$red:           rgba(240,76,92,1);
$red-dark:      darken($red, 15%);
$red-invalid:   #DD1327;

$orange:        rgba(240,142,78,1);

$green:         rgba(110,198,84,1);

$blue:          rgba(65,158,218,1);
$blue-bg:       #EAF3FF;
$blue-darker:   darken($blue, 30%);

$co-gray:          #777;
$co-gray-light:    #ccc;
$co-gray-lighter:  #eee;
$co-gray-lightest: #f5f5f5;
$co-gray-dark:     #666;
$co-gray-darker:   #333;

$black:         rgba(0,0,0,1);
