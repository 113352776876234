.co-m-route-status-icon {
  padding-right: 6px;
  width: 16px;
}

.co-m-route-tls-reveal__title {
  display: flex;
  justify-content: space-between;
}

.co-m-route-tls-reveal__btn {
  padding-right: 0;
}

.co-m-route-ingress-status + .co-m-route-ingress-status {
  margin-top: 40px;
}
