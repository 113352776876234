$sidebar-width: 220px;

html,
body,
#app,
#content {
  height: 100%; // so .co-p-has-sidebar, .yaml-editor are full height
}

#content {
  padding-top: $masthead-height-mobile;
  @media (min-width: $grid-float-breakpoint) {
    padding-left: $sidebar-width;
    padding-top: $masthead-height-desktop;
  }
}

#sidebar {
  background-color: $color-os-nav-background;
  bottom: 0;
  display: flex;
  flex-direction: column;
  left: 0;
  position: fixed;
  top: $masthead-height-mobile;
  width: $sidebar-width;
  z-index: $sidebar-z-index;
  @media (max-width: $grid-float-breakpoint-max) {
    left: -($sidebar-width);
    &.open {
      box-shadow: 2px 0 3px rgba(0,0,0,.3);
      left: 0;
      transition: left .1s ease-in-out;
    }
  }
  @media (min-width: $grid-float-breakpoint) {
    top: $masthead-height-desktop;
  }
}


.sidebar-toggle {
  background-color: transparent;
  border: 0;
  margin: 4px 5px;
  padding: 9px 10px;
  position: fixed;
  z-index: $masthead-z-index;
  @media (min-width: $grid-float-breakpoint) {
    display: none;
  }
  &:focus,
  &:hover {
    .icon-bar {
      background: $navbar-pf-vertical-navbar-toggle-icon-bar-hover-bg;
    }
  }
  .icon-bar {
    background: $navbar-pf-vertical-color;
    border-radius: 1px;
    display: block;
    height: 2px;
    width: 20px;
  }
  .icon-bar + .icon-bar {
    margin-top: 4px;
  }
}

.absolute-zero {
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
}

.full-width-and-height {
  width: 100%;
  height: 100%;
}

.co-p-has-sidebar {
  position: relative;
  @media(min-width: $screen-md-min) {
    display: flex;
    height: 100%;
  }

  &__body {
    flex: 1 1 700px;
  }

  &__sidebar {
    @media(min-width: $screen-md-min) {
      flex: 1 0 300px;
      overflow-y: auto;
    }

    &--bordered {
      @media(max-width: $screen-sm-max) {
        border-top: 1px solid #ccc;
      }
      @media(min-width: $screen-md-min) {
        border-left: 1px solid #ccc;
      }
    }
  }

  &__sidebar-heading {
    font-size: 18px;
    margin-bottom: 20px;
    margin-top: 45px;
  }

  &__sidebar-heading--first {
    margin-top: 0;
  }

  &__sidebar--hidden {
    position: absolute;
    right: 13px;
    top: 8px;
    z-index: 1;
  }
}
