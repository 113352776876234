@import url(~xterm/dist/xterm.css);

.xterm.fullscreen {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: auto;
  height: auto;
  z-index: 255;
}

button:focus,
a:focus {
  outline-style: none;
}

ul,
ol {
  padding-left: 20px;
}

dl {
  margin: 0px;
}

dt {
  text-transform: uppercase;
  font-weight: 400;
  color: #666;
  font-size: 12px;
}

dd {
  margin-bottom: 20px;
}

dd:last-child {
  margin-bottom: 0px;
}

.help-block {
  margin-bottom: 0px;
}

.table {
  margin-bottom: 0px;
}

.table thead>tr>th {
  border-bottom: 0px;
}

.table th {
  text-transform: uppercase;
  padding-top: 0px !important;
}

.table tr:last-child {
  border-bottom: 1px solid #eee;
}

.table td {
  border-color: #eee !important;
  vertical-align: middle !important;
}

.table td input[type="radio"],
.table td input[type="checkbox"] {
  margin-top: 0px;
  vertical-align: middle;
}

.table-hover>tbody>tr:hover>td,
.table-hover>tbody>tr:hover>th {
  background-color: #EAF3FF;
}

.co-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
}

.co-nowrap {
  white-space: nowrap;
}

.co-an-fade-in-out {
  -webkit-transition-property: compact(opacity, false, false, false, false, false, false, false, false, false);
  -moz-transition-property: compact(opacity, false, false, false, false, false, false, false, false, false);
  -o-transition-property: compact(opacity, false, false, false, false, false, false, false, false, false);
  transition-property: compact(opacity, false, false, false, false, false, false, false, false, false);
  -webkit-transition-duration: compact(0.2s, false, false, false, false, false, false, false, false, false);
  -moz-transition-duration: compact(0.2s, false, false, false, false, false, false, false, false, false);
  -o-transition-duration: compact(0.2s, false, false, false, false, false, false, false, false, false);
  transition-duration: compact(0.2s, false, false, false, false, false, false, false, false, false);
  -webkit-transition-timing-function: compact(linear, false, false, false, false, false, false, false, false, false);
  -moz-transition-timing-function: compact(linear, false, false, false, false, false, false, false, false, false);
  -o-transition-timing-function: compact(linear, false, false, false, false, false, false, false, false, false);
  transition-timing-function: compact(linear, false, false, false, false, false, false, false, false, false);
}

.co-an-fade-in-out.ng-hide-add,
.co-an-fade-in-out.ng-hide-remove {
  display: block !important;
}

.co-an-fade-in-out.ng-hide-add.ng-hide-add-active,
.co-an-fade-in-out.ng-hide-remove {
  opacity: 0;
}

.co-an-fade-in-out.ng-hide-add,
.co-an-fade-in-out.ng-hide-remove.ng-hide-remove-active {
  opacity: 1;
}

.co-an-fade-in-out.ng-enter,
.co-an-fade-in-out.ng-move {
  opacity: 0;
}

.co-an-fade-in-out.ng-enter.ng-enter-active,
.co-an-fade-in-out.ng-move.ng-move-active {
  opacity: 1;
}

.co-an-fade-in-out.ng-leave {
  opacity: 1;
}

.co-an-fade-in-out.ng-leave.ng-leave-active {
  opacity: 0;
}

input[type="number"] {
  max-width: 100px;
  -moz-appearance: textfield;
  -webkit-appearance: textfield;
  appearance: textfield;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  margin: 0;
}

input[type="number"]+.input-group-addon {
  width: auto;
}

input[type="radio"] {
  margin-right: 7px;
}

.co-m-radio-desc {
  margin-left: 20px;
}

.co-m-form-row,
.form-row {
  margin-bottom: 20px;
}

.co-m-form-row:last-child {
  margin-bottom: 0px;
}

.co-m-form-col {
  max-width: 600px;
}

.co-m-modal-link {
  cursor: pointer;
  position: relative;
  padding-right: 9px;
  outline: none;
}

.co-m-modal-link:after {
  color: #777;
  content: "\f105";
  font-family: "FontAwesome";
  pointer-events: none;
  position: absolute;
  right: 0;
}

.co-m-modal-link:hover:after {
  color: #666;
}

.co-m-modal-link:focus {
  outline: none;
}

.co-m-invisible-input {
  border: 0;
  background: none;
  width: 100%;
  outline: 0;
}

.co-m-invisible-input:hover {
  cursor: copy;
}

.co-m-primary-action {
  color: white;
  font-size: 90%;
  font-weight: 200;
  white-space: nowrap;
  cursor: pointer;
}

.co-m-primary-action:hover,
.co-m-primary-action:visited {
  color: white;
}

.co-m-pane__body-group {
  padding: 0 0 30px 0;
}

.co-m-table-grid .row {
  line-height: normal;
  margin: 0;
}

.co-m-table-grid .row,
.co-m-table-grid [class*='col-'] {
  vertical-align: middle;
}

.co-m-table-grid .co-m-table-grid {
  padding-left: 20px;
}

.co-m-table-grid__body {
  min-height: 50px;
  position: relative;
  width: 100%;
}

.co-m-table-grid .co-m-table-grid__body .row {
  padding: 10px 0;
}

.co-m-table-grid--bordered .co-m-table-grid__head,
.co-m-table-grid--bordered .co-m-table-grid__body .row {
  border-bottom: solid 1px #eee;
}

.co-m-table-grid__head {
  font-size: 12px;
  padding: 10px 0;
}

.co-m-table-grid__head a {
  cursor: pointer;
}

.co-m-loader,
.co-m-inline-loader {
  min-width: 18px;
}

.co-m-loader {
  display: block;
  position: absolute;
  left: 50%;
  top: 50%;
  margin: -11px 0 0 -13px;
}

.co-m-inline-loader {
  display: inline-block;
  cursor: default;
}

.co-m-inline-loader:hover {
  text-decoration: none;
}

.co-m-loader-dot__one,
.co-m-loader-dot__two,
.co-m-loader-dot__three {
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  -o-border-radius: 3px;
  border-radius: 3px;
  animation-fill-mode: both;
  -webkit-animation-fill-mode: both;
  -moz-animation-fill-mode: both;
  -ms-animation-fill-mode: both;
  -o-animation-fill-mode: both;
  animation-name: bouncedelay;
  animation-duration: 1s;
  animation-timing-function: ease-in-out;
  animation-delay: 0;
  animation-direction: normal;
  animation-iteration-count: infinite;
  animation-fill-mode: forwards;
  animation-play-state: running;
  -webkit-animation-name: bouncedelay;
  -webkit-animation-duration: 1s;
  -webkit-animation-timing-function: ease-in-out;
  -webkit-animation-delay: 0;
  -webkit-animation-direction: normal;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-fill-mode: forwards;
  -webkit-animation-play-state: running;
  -moz-animation-name: bouncedelay;
  -moz-animation-duration: 1s;
  -moz-animation-timing-function: ease-in-out;
  -moz-animation-delay: 0;
  -moz-animation-direction: normal;
  -moz-animation-iteration-count: infinite;
  -moz-animation-fill-mode: forwards;
  -moz-animation-play-state: running;
  display: inline-block;
  height: 6px;
  width: 6px;
  background: #419eda;
  border-radius: 100%;
  display: inline-block;
}

.co-m-loader-dot__one {
  animation-delay: -0.32s;
  -webkit-animation-delay: -0.32s;
  -moz-animation-delay: -0.32s;
  -ms-animation-delay: -0.32s;
  -o-animation-delay: -0.32s;
}

.co-m-loader-dot__two {
  animation-delay: -0.16s;
  -webkit-animation-delay: -0.16s;
  -moz-animation-delay: -0.16s;
  -ms-animation-delay: -0.16s;
  -o-animation-delay: -0.16s;
}

@-webkit-keyframes bouncedelay {
  0%, 80%, 100% {
    -webkit-transform: scale(0.25, 0.25);
    -moz-transform: scale(0.25, 0.25);
    -ms-transform: scale(0.25, 0.25);
    -o-transform: scale(0.25, 0.25);
    transform: scale(0.25, 0.25);
  }

  40% {
    -webkit-transform: scale(1, 1);
    -moz-transform: scale(1, 1);
    -ms-transform: scale(1, 1);
    -o-transform: scale(1, 1);
    transform: scale(1, 1);
  }
}

@-moz-keyframes bouncedelay {
  0%, 80%, 100% {
    -webkit-transform: scale(0.25, 0.25);
    -moz-transform: scale(0.25, 0.25);
    -ms-transform: scale(0.25, 0.25);
    -o-transform: scale(0.25, 0.25);
    transform: scale(0.25, 0.25);
  }

  40% {
    -webkit-transform: scale(1, 1);
    -moz-transform: scale(1, 1);
    -ms-transform: scale(1, 1);
    -o-transform: scale(1, 1);
    transform: scale(1, 1);
  }
}

@-ms-keyframes bouncedelay {
  0%, 80%, 100% {
    -webkit-transform: scale(0.25, 0.25);
    -moz-transform: scale(0.25, 0.25);
    -ms-transform: scale(0.25, 0.25);
    -o-transform: scale(0.25, 0.25);
    transform: scale(0.25, 0.25);
  }

  40% {
    -webkit-transform: scale(1, 1);
    -moz-transform: scale(1, 1);
    -ms-transform: scale(1, 1);
    -o-transform: scale(1, 1);
    transform: scale(1, 1);
  }
}

@keyframes bouncedelay {
  0%, 80%, 100% {
    -webkit-transform: scale(0.25, 0.25);
    -moz-transform: scale(0.25, 0.25);
    -ms-transform: scale(0.25, 0.25);
    -o-transform: scale(0.25, 0.25);
    transform: scale(0.25, 0.25);
  }

  40% {
    -webkit-transform: scale(1, 1);
    -moz-transform: scale(1, 1);
    -ms-transform: scale(1, 1);
    -o-transform: scale(1, 1);
    transform: scale(1, 1);
  }
}

.co-m-cog {
  position: relative;
  color: #999;
  display: inline-block;
  font-size: 14px;
}

.co-m-cog:hover {
  cursor: pointer;
}

.co-m-cog.open .co-m-cog__icon {
  color: #2276ad;
}

.co-m-cog--disabled {
  pointer-events: none;
}

.co-m-cog-wrapper {
  display: inline-block;
}

.co-m-cog__icon--disabled {
  color: #ddd;
}

h1,
.h1,
h2,
.h2 {
  font-weight: normal;
}

.text-secondary {
  color: #666;
}

small {
  font-size: 12px;
}

.form-control[disabled],
fieldset[disabled] .form-control {
  cursor: auto;
}

.text-filter {
  width: 250px;
  min-width: 150px;
}

.co-m-primary-action {
  color: black;
}

.co-m-primary-action:hover,
.co-m-primary-action:visited {
  color: black;
}

.no-margin {
  margin: 0 !important;
}

tags-input:focus,
tags-input .host:focus {
  outline: none;
}

tags-input .host {
  position: relative;
  margin-top: 5px;
  margin-bottom: 5px;
}

tags-input .host:active {
  outline: none;
}

tags-input .tags {
  cursor: text;
  min-height: 27px;
  padding: 0 6px;
  font-size: 13px;
  line-height: 19px;
  color: #363636;
  background-color: #fff;
  background-image: none;
  border: 1px solid #bbb;
  border-radius: 1px;
  -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,0.075);
  box-shadow: inset 0 1px 1px rgba(0,0,0,0.075);
  -webkit-transition: border-color ease-in-out 0.15s,box-shadow ease-in-out 0.15s;
  -o-transition: border-color ease-in-out 0.15s,box-shadow ease-in-out 0.15s;
  transition: border-color ease-in-out 0.15s,box-shadow ease-in-out 0.15s;
}

.modal-body tags-input .tags {
  min-height: 200px;
}

tags-input .tags.focused,
tags-input .tags:focus {
  outline: none;
  border-color: #66AFE9;
}

tags-input .tags .tag-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

tags-input .tags .tag-item {
  word-break: break-all;
}

tags-input .tags .tag-item.selected {
  border: solid 1px;
}

tags-input .tags .tag-item .remove-button {
  margin: 0 0 0 2px;
  padding: 0;
  border: none;
  background: none;
  cursor: pointer;
  vertical-align: middle;
  font-size: 16px;
  color: #585858;
}

tags-input .tags .tag-item .remove-button:active {
  color: #fff;
}

tags-input .remove-button:active,
tags-input .remove-button:hover {
  text-decoration: none;
}

tags-input .tags .input {
  background: transparent;
  border: 1px solid transparent;
  line-height: 19px;
  margin: 2px 0;
  outline: none;
  padding: 0;
}

@supports (-webkit-overflow-scrolling: touch) {
@media (max-width: 767px) {
    tags-input .tags .input {
      border: 0;
      line-height: 24px;
      margin-bottom: 0;
      margin-top: 0;
    }
}
}

tags-input .tags .input.invalid-tag {
  color: #DD1327;
}

tags-input .tags .input::-ms-clear {
  display: none;
}

tags-input.ng-invalid .tags {
  border-color: #DD1327;
}

tags-input .autocomplete {
  margin-top: 5px;
  position: absolute;
  padding: 5px 0;
  z-index: 999;
  width: 100%;
  background-color: white;
  border: 1px solid rgba(0,0,0,0.2);
  -webkit-box-shadow: 0 5px 10px rgba(0,0,0,0.2);
  -moz-box-shadow: 0 5px 10px rgba(0,0,0,0.2);
  box-shadow: 0 5px 10px rgba(0,0,0,0.2);
}

tags-input .autocomplete .suggestion-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

tags-input .autocomplete .suggestion-item {
  padding: 5px 10px;
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font: 16px "Helvetica Neue", Helvetica, Arial, sans-serif;
  color: black;
  background-color: white;
}

tags-input .autocomplete .suggestion-item.selected {
  color: white;
  background-color: #0097cf;
}

tags-input .autocomplete .suggestion-item.selected em {
  color: white;
  background-color: #0097cf;
}

tags-input .autocomplete .suggestion-item em {
  font: normal bold 16px "Helvetica Neue", Helvetica, Arial, sans-serif;
  color: black;
  background-color: white;
}

.clickable {
  cursor: pointer;
}

.btn+.btn {
  margin-left: 3px;
}

.breadcrumb {
  margin-bottom: 0;
  padding-bottom: 15px;
  padding-top: 15px;
}

.modal-footer .alert {
  text-align: left;
}

.ReactVirtualized__Grid.ReactVirtualized__List {
  overflow: visible !important;
}

.ReactVirtualized__Grid__innerScrollContainer {
  overflow: visible !important;
}

.co-m-pane__body {
  border-bottom: 1px solid #ccc;
  margin: 30px 0 0;
  padding: 0 15px 30px;
}

@media (min-width: 768px) {
  .co-m-pane__body {
    padding-left: 30px;
    padding-right: 30px;
  }
}

.co-m-pane__body:last-child {
  border-bottom: 0;
}

.co-m-pane__filter-bar {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 20px 15px;
}

@media (min-width: 768px) {
  .co-m-pane__filter-bar {
    margin-left: 30px;
    margin-right: 30px;
    margin-top: 30px;
  }
}

.co-m-pane__filter-bar .btn-group,
.co-m-pane__filter-bar .co-m-primary-action,
.co-m-pane__filter-bar .form-control {
  margin-bottom: 10px;
}

.co-m-pane__filter-bar .btn-group {
  margin-right: 10px;
  min-width: 0;
}

.co-m-pane__filter-bar--alt {
  margin-left: 0;
  margin-right: 0;
}

.co-m-pane__filter-bar--group__body {
  margin-top: 0;
  padding-top: 30px;
}

.co-m-pane__filter-bar-group {
  display: flex;
  flex: 1 1 auto;
  flex-wrap: wrap;
  min-width: 0;
}

@media (min-width: 480px) {
  .co-m-pane__filter-bar-group--filter {
    flex: 1 1 auto;
    justify-content: flex-end;
  }
}

.co-m-pane__filter-bar-group--filters {
  flex-wrap: wrap;
  justify-content: space-between;
}

.co-m-pane__filter-bar-group--filters .btn-group {
  display: flex;
}

.co-m-pane__filter-bar-group+.co-m-pane__filter-bar-group--filters {
  flex-grow: 0;
}

.co-m-pane__heading {
  display: flex;
  justify-content: space-between;
  margin: 0 0 30px;
}

.co-m-pane__heading--center {
  justify-content: center;
}

.co-m-pane__heading--logo {
  align-items: center;
  margin-bottom: 15px;
}

.co-m-pane__name {
  overflow-wrap: break-word;
  word-wrap: break-word;
  flex: 1;
  margin-right: 10px;
  min-width: 0;
}

.co-inline-block {
  display: inline-block;
}

.co-no-bold {
  font-weight: normal;
}

.cos-error-title {
  color: #f04c5c;
}

.co-section-heading {
  display: flex;
  font-size: 20px;
  font-weight: 600;
  justify-content: space-between;
  margin: 0 0 20px 0;
}

.co-section-heading--contains-resource-icon {
  align-items: center;
  justify-content: flex-start;
}

.co-section-heading-secondary {
  font-size: 16px;
  font-weight: 600;
  margin: 30px 0;
}

.co-table-container {
  overflow: auto;
  margin-bottom: 30px;
}

.co-table-container table thead th {
  color: #666;
  font-weight: 400;
}

.co-m-pane__details {
  margin-bottom: 20px;
}

.co-detail-table__row .co-m-pane__details {
  margin-bottom: 0;
}

.co-m-pane__details dd {
  font-size: 14px;
  overflow-wrap: break-word;
  word-wrap: break-word;
}

.co-m-pane__explanation {
  color: #777;
  margin-bottom: 30px;
}

.co-m-pane__explanation code {
  background-color: #f5f5f5;
  color: #777;
}

.co-btn--download {
  padding: 15px;
  font-size: 18px;
  text-align: center;
}

.co-btn--download,
.co-btn--download:hover {
  border: 1px solid #DDDDDD;
  border-radius: 2px;
}

.co-pre-line {
  white-space: pre-line;
}

.co-pre-wrap {
  white-space: pre-wrap;
}

.co-search {
  padding-bottom: 30px;
}

.co-search .input-group-btn {
  vertical-align: top;
}

@media (max-width: 767px) {
  .co-search .input-group-btn {
    display: flex;
    min-width: 0;
    width: auto;
  }

  .co-search .input-group-btn .co-type-selector {
    width: 100%;
  }
}

@media (max-width: 767px) {
  .co-search .input-group-select {
    display: block;
  }

  .co-search .input-group-select .btn--dropdown {
    margin-bottom: 2px;
    width: 100%;
  }
}

.co-well {
  color: #154a6d;
  background-color: #EAF3FF;
  padding: 20px;
  margin: 30px;
}

@supports (-webkit-overflow-scrolling: touch) {
@media (max-width: 767px) {
    input,
    select,
    .tag-item,
    textarea {
      font-size: 16px !important;
    }
}
}

input[type="number"] {
  -moz-appearance: textfield;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.co-error {
  color: #D64456;
  white-space: nowrap;
}

.co-overflow-y-fade {
  overflow: hidden;
  white-space: nowrap;
}

.error-message {
  color: #fff;
  font-size: 14px;
  padding: 2px 12px;
  background-color: #D64456;
}

.co-break-word {
  overflow-wrap: break-word;
  word-wrap: break-word;
}

.co-external-link:after {
  content: "\f08e";
  display: inline-block;
  font-family: 'FontAwesome';
  font-size: 75%;
  height: 16px;
  position: relative;
  margin-left: 5px;
  margin-right: -25px;
  top: 0;
  width: 20px;
}

.co-external-link {
  display: inline-block;
  padding-right: 25px;
}

.co-external-link--block {
  display: block;
}

.co-toolbar {
  align-items: stretch;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 5px;
}

.co-toolbar__item {
  max-width: 100%;
  padding: 5px 0;
}

.co-toolbar__item .btn-group {
  max-width: 100%;
}

.co-toolbar__group {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  min-width: 0;
}

@media (max-width: 767px) {
  .co-toolbar__group {
    flex-direction: column;
    justify-content: flex-end;
  }
}

.co-toolbar__group--left {
  justify-content: flex-start;
}

.co-toolbar__group--left .co-toolbar__item {
  padding-right: 15px;
}

@media (max-width: 767px) {
  .co-toolbar__group--left {
    align-items: flex-start;
  }
}

.co-toolbar__group--right {
  justify-content: flex-end;
}

@media (min-width: 480px) {
  .co-toolbar__group--right .co-toolbar__item {
    padding-left: 15px;
  }
}

@media (max-width: 767px) {
  .co-toolbar__group--right {
    align-items: flex-end;
  }
}

.node-ready,
.pvc-bound,
.route-accepted {
  color: #3f9c35;
}

.node-not-ready,
.pvc-lost,
.route-rejected {
  color: #D64456;
}

.co-icon-space-l {
  margin-left: 0.25em;
}

.co-icon-space-r {
  margin-right: 0.25em;
}

html,
body,
#app,
#content {
  height: 100%;
}

#content {
  padding-top: 40px;
}

@media (min-width: 768px) {
  #content {
    padding-left: 220px;
    padding-top: 60px;
  }
}

#sidebar {
  background-color: #292e34;
  bottom: 0;
  display: flex;
  flex-direction: column;
  left: 0;
  position: fixed;
  top: 40px;
  width: 220px;
  z-index: 1030;
}

@media (max-width: 767px) {
  #sidebar {
    left: -220px;
  }

  #sidebar.open {
    box-shadow: 2px 0 3px rgba(0,0,0,0.3);
    left: 0;
    transition: left .1s ease-in-out;
  }
}

@media (min-width: 768px) {
  #sidebar {
    top: 60px;
  }
}

.sidebar-toggle {
  background-color: transparent;
  border: 0;
  margin: 4px 5px;
  padding: 9px 10px;
  position: fixed;
  z-index: 1031;
}

@media (min-width: 768px) {
  .sidebar-toggle {
    display: none;
  }
}

.sidebar-toggle:focus .icon-bar,
.sidebar-toggle:hover .icon-bar {
  background: #fff;
}

.sidebar-toggle .icon-bar {
  background: #d1d1d1;
  border-radius: 1px;
  display: block;
  height: 2px;
  width: 20px;
}

.sidebar-toggle .icon-bar+.icon-bar {
  margin-top: 4px;
}

.absolute-zero {
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
}

.full-width-and-height {
  width: 100%;
  height: 100%;
}

.co-p-has-sidebar {
  position: relative;
}

@media (min-width: 992px) {
  .co-p-has-sidebar {
    display: flex;
    height: 100%;
  }
}

.co-p-has-sidebar__body {
  flex: 1 1 700px;
}

@media (min-width: 992px) {
  .co-p-has-sidebar__sidebar {
    flex: 1 0 300px;
    overflow-y: auto;
  }
}

@media (max-width: 991px) {
  .co-p-has-sidebar__sidebar--bordered {
    border-top: 1px solid #ccc;
  }
}

@media (min-width: 992px) {
  .co-p-has-sidebar__sidebar--bordered {
    border-left: 1px solid #ccc;
  }
}

.co-p-has-sidebar__sidebar-heading {
  font-size: 18px;
  margin-bottom: 20px;
  margin-top: 45px;
}

.co-p-has-sidebar__sidebar-heading--first {
  margin-top: 0;
}

.co-p-has-sidebar__sidebar--hidden {
  position: absolute;
  right: 13px;
  top: 8px;
  z-index: 1;
}

.co-text-configmap {
  color: #40199a;
}

.co-text-configmap:link,
.co-text-configmap:visited,
.co-text-configmap:hover,
.co-text-configmap:active {
  color: #40199a;
  text-decoration: none;
}

.co-text-daemonset {
  color: #004368;
}

.co-text-daemonset:link,
.co-text-daemonset:visited,
.co-text-daemonset:hover,
.co-text-daemonset:active {
  color: #004368;
  text-decoration: none;
}

.co-text-deployment {
  color: #004368;
}

.co-text-deployment:link,
.co-text-deployment:visited,
.co-text-deployment:hover,
.co-text-deployment:active {
  color: #004368;
  text-decoration: none;
}

.co-text-deploymentconfig {
  color: #004368;
}

.co-text-deploymentconfig:link,
.co-text-deploymentconfig:visited,
.co-text-deploymentconfig:hover,
.co-text-deploymentconfig:active {
  color: #004368;
  text-decoration: none;
}

.co-text-ingress {
  color: #1f0066;
}

.co-text-ingress:link,
.co-text-ingress:visited,
.co-text-ingress:hover,
.co-text-ingress:active {
  color: #1f0066;
  text-decoration: none;
}

.co-text-job {
  color: #004368;
}

.co-text-job:link,
.co-text-job:visited,
.co-text-job:hover,
.co-text-job:active {
  color: #004368;
  text-decoration: none;
}

.co-text-namespace {
  color: #2d7623;
}

.co-text-namespace:link,
.co-text-namespace:visited,
.co-text-namespace:hover,
.co-text-namespace:active {
  color: #2d7623;
  text-decoration: none;
}

.co-text-node {
  color: #703fec;
}

.co-text-node:link,
.co-text-node:visited,
.co-text-node:hover,
.co-text-node:active {
  color: #703fec;
  text-decoration: none;
}

.co-text-pod {
  color: #007a87;
}

.co-text-pod:link,
.co-text-pod:visited,
.co-text-pod:hover,
.co-text-pod:active {
  color: #007a87;
  text-decoration: none;
}

.co-text-project {
  color: #2d7623;
}

.co-text-project:link,
.co-text-project:visited,
.co-text-project:hover,
.co-text-project:active {
  color: #2d7623;
  text-decoration: none;
}

.co-text-replicaset {
  color: #004368;
}

.co-text-replicaset:link,
.co-text-replicaset:visited,
.co-text-replicaset:hover,
.co-text-replicaset:active {
  color: #004368;
  text-decoration: none;
}

.co-text-replicationcontroller {
  color: #004368;
}

.co-text-replicationcontroller:link,
.co-text-replicationcontroller:visited,
.co-text-replicationcontroller:hover,
.co-text-replicationcontroller:active {
  color: #004368;
  text-decoration: none;
}

.co-text-secret {
  color: #ec7a08;
}

.co-text-secret:link,
.co-text-secret:visited,
.co-text-secret:hover,
.co-text-secret:active {
  color: #ec7a08;
  text-decoration: none;
}

.co-text-service {
  color: #6ca100;
}

.co-text-service:link,
.co-text-service:visited,
.co-text-service:hover,
.co-text-service:active {
  color: #6ca100;
  text-decoration: none;
}

.co-text-serviceaccount {
  color: #40199a;
}

.co-text-serviceaccount:link,
.co-text-serviceaccount:visited,
.co-text-serviceaccount:hover,
.co-text-serviceaccount:active {
  color: #40199a;
  text-decoration: none;
}

.co-text-servicemonitor {
  color: #6ca100;
}

.co-text-servicemonitor:link,
.co-text-servicemonitor:visited,
.co-text-servicemonitor:hover,
.co-text-servicemonitor:active {
  color: #6ca100;
  text-decoration: none;
}

.co-text-alertmanager {
  color: #b35c00;
}

.co-text-alertmanager:link,
.co-text-alertmanager:visited,
.co-text-alertmanager:hover,
.co-text-alertmanager:active {
  color: #b35c00;
  text-decoration: none;
}

.co-m-status {
  text-align: right;
}

.co-m-status__icon {
  padding-right: 5px;
}

.co-m-status--critical {
  color: #dd1326;
}

.co-m-status--ok {
  color: #6ec654;
}

.co-m-status--warning {
  color: #f08e4e;
}

.co-m-status--unknown {
  color: #777;
}

.co-m-status--access-denied {
  color: #777;
}

.co-copy-to-clipboard {
  font-family: Menlo,Monaco,Consolas,monospace;
  position: relative;
}

.co-copy-to-clipboard__btn {
  position: absolute;
  right: 0px;
  top: 0px;
  width: 42px;
  height: 42px;
}

.co-copy-to-clipboard__text {
  padding-right: 50px;
}

.co-disabled {
  position: relative;
}

.co-disabled::before {
  background-color: #fff;
  bottom: 0;
  content: '';
  cursor: not-allowed;
  left: 0;
  opacity: 0.4;
  overflow: hidden;
  position: absolute;
  right: 0;
  top: 0;
  user-select: none;
  z-index: 10;
}

.co-btn-file {
  position: relative;
  overflow: hidden;
}

.co-btn-file input[type=file] {
  background: white;
  cursor: inherit;
  display: block;
  filter: alpha(opacity=0);
  font-size: 100px;
  min-height: 100%;
  min-width: 100%;
  opacity: 0;
  outline: none;
  position: absolute;
  right: 0;
  text-align: right;
  top: 0;
}

.co-m-label,
tags-input .tags .tag-item {
  background-color: #f5f5f5;
  border-radius: 12px;
  border: 1px solid #eee;
  display: inline-flex;
  line-height: 19px;
  margin: 2px 2px 2px 0;
  max-width: 100%;
  padding: 0 8px;
}

.co-m-label__key,
.co-m-label__value {
  max-width: 60px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.co-m-label-list:hover .co-m-label .co-m-label__key,
.co-m-label-list:hover tags-input .tags .tag-item .co-m-label__key,
tags-input .tags .co-m-label-list:hover .tag-item .co-m-label__key,
.co-m-label-list:hover .co-m-label .co-m-label__value,
.co-m-label-list:hover tags-input .tags .tag-item .co-m-label__value,
tags-input .tags .co-m-label-list:hover .tag-item .co-m-label__value,
.co-m-label--expand .co-m-label__key,
.co-m-label--expand .co-m-label__value {
  max-width: 100%;
}

.co-m-number-spinner__input {
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  display: inline-block;
}

.co-m-number-spinner__up-icon,
.co-m-number-spinner__down-icon {
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  cursor: pointer;
  font-size: 20px;
  vertical-align: middle;
  margin-top: -1px;
}

.co-m-number-spinner__up-icon {
  margin-left: 10px;
}

.co-m-number-spinner__down-icon {
  margin-right: 10px;
}

.cos-status-box {
  padding: 20px;
}

.cos-status-box__title {
  color: #333;
  font-size: 18px;
  margin-bottom: 20px;
  text-align: center;
}

.cos-status-box__title .cos-error-title {
  color: #f04c5c;
}

.cos-status-box__detail {
  color: #777;
  font-size: 14px;
  max-width: 500px;
  margin: 0 auto;
}

.cos-status-box__access-denied-icon {
  margin: 10px;
}

.co-m-loader--inline {
  display: inline-block;
  margin: 0;
  position: static;
}

.co-m-timeout-error {
  margin-bottom: 30px;
  text-align: center;
}

.co-cluster-updates__component .co-m-timeout-error,
.co-m-vert-nav .co-m-timeout-error {
  margin-top: 30px;
}

.co-m-requirement {
  font-size: 13px;
  vertical-align: middle;
}

.log-window {
  color: #f5f5f5;
}

.log-window__header {
  background-color: #292e34;
  padding: 8px 10px 5px 10px;
}

.log-window__body {
  background-color: #030303;
  font-family: Menlo,Monaco,Consolas,monospace;
}

.log-window__scroll-pane {
  overflow: auto;
  padding-top: 10px;
}

.log-window__contents__text {
  padding-left: 10px;
  padding-right: 10px;
  white-space: pre;
  width: 0;
}

.log-window__resume-btn {
  background-color: #292e34;
  color: #f5f5f5;
  padding: 4px 10px;
}

.log-window__resume-btn:hover,
.log-window__resume-btn:focus,
.log-window__resume-btn:active {
  color: #f5f5f5;
}

@keyframes build-progress-line {
  to {
    width: 100%;
  }
}

@keyframes build-progress-rail {
  to {
    transform: translateX(400%);
  }
}

@keyframes build-progress {
  to {
    transform: rotate(180deg);
  }
}

@keyframes pipeline-stage-fadeIcon {
  to {
    opacity: 1;
  }
}

@keyframes pipeline-stage-fadeOut {
  to {
    background-color: transparent;
  }
}

.build-pipeline {
  border: 1px solid #d1d1d1;
  font-size: 12px;
  margin-bottom: 30px;
}

.build-pipeline:first-child {
  border-top-width: 1px;
}

@media (min-width: 600px) {
  .build-pipeline {
    display: flex;
    flex: 1 1 0%;
    flex-direction: row;
  }
}

.build-pipeline__container {
  flex: 1 1 auto;
  overflow: hidden;
}

.build-pipeline__stages {
  display: flex;
  flex-wrap: wrap;
  height: 100%;
  padding: 0 5px;
}

.build-pipeline__stage {
  flex: 0 0 auto;
  min-height: 96px;
  padding: 15px 10px 30px 10px;
  position: relative;
  width: 100%;
}

.build-pipeline__stage:before {
  bottom: 0;
  color: #c4c4c4;
  content: '\2193';
  font-size: 22px;
  left: 0;
  line-height: 1;
  position: absolute;
  right: 0;
  text-align: center;
}

.build-pipeline__stage:last-child:before {
  display: none;
}

@media (min-width: 480px) {
  .build-pipeline__stage {
    padding-right: 52px;
    padding-bottom: 15px;
    width: 33.33333%;
  }

  .build-pipeline__stage:before {
    bottom: auto;
    content: '\2192';
    left: auto;
    right: 10px;
    top: 37%;
  }
}

@media (min-width: 992px) {
  .build-pipeline__stage {
    width: 25%;
  }
}

@media (min-width: 1200px) {
  .build-pipeline__stage {
    width: 20%;
  }
}

@media (min-width: 1400px) {
  .build-pipeline__stage {
    width: 16.66667%;
  }
}

@media (min-width: 1600px) {
  .build-pipeline__stage {
    width: 14.28571%;
  }
}

.build-pipeline__stage--none {
  align-items: center;
  display: flex;
  padding: 0 10px;
}

@media (max-width: 767px) {
  .build-pipeline__stage--none {
    justify-content: center;
  }
}

.build-pipeline__stage-name,
.build-pipeline__stage-time,
.build-pipeline__stage-actions {
  font-size: 12px;
  text-align: center;
}

.build-pipeline__stage-name {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-bottom: 13px;
}

.build-pipeline__stage-time,
.build-pipeline__stage-actions {
  margin-top: 12px;
}

.build-pipeline__stage-time--in-progress,
.build-pipeline__stage-actions--in-progress {
  color: #777;
}

.build-pipeline__status-icon--complete {
  color: #3f9c35;
}

.build-pipeline__status-icon--failed {
  color: #c00;
}

.build-pipeline__summary {
  border-bottom: 1px solid #d1d1d1;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  padding: 5px;
  position: relative;
  text-align: center;
}

@media (min-width: 600px) {
  .build-pipeline__summary {
    border-bottom-width: 0;
    border-right: 1px solid #d1d1d1;
    flex: 0 0 125px;
    flex-direction: column;
    justify-content: center;
  }
}

.build-pipeline__status-bar .build-pipeline__animation-line:before,
.build-pipeline__status-bar .build-pipeline__circle-clip1:before,
.build-pipeline__status-bar .build-pipeline__circle-clip2:before {
  background-color: #d1d1d1;
}

.build-pipeline__status-bar .build-pipeline__circle-inner-fill {
  background-color: #fff;
  opacity: 0;
}

.build-pipeline__status-bar--success .build-pipeline__animation-line:before,
.build-pipeline__status-bar--success .build-pipeline__circle-clip1:before,
.build-pipeline__status-bar--success .build-pipeline__circle-clip2:before,
.build-pipeline__status-bar--success .build-pipeline__circle-inner-fill {
  background-color: #3f9c35;
}

.build-pipeline__status-bar--success .build-pipeline__animation-circle {
  animation: pipeline-stage-fadeInSuccess 0s .7s linear forwards;
}

.build-pipeline__status-bar--success .build-pipeline__animation-circle:after {
  content: "";
}

.build-pipeline__status-bar--failed .build-pipeline__animation-line:before,
.build-pipeline__status-bar--failed .build-pipeline__circle-clip1:before,
.build-pipeline__status-bar--failed .build-pipeline__circle-clip2:before,
.build-pipeline__status-bar--failed .build-pipeline__circle-inner-fill {
  background-color: #c00;
}

.build-pipeline__status-bar--failed .build-pipeline__animation-circle {
  animation: pipeline-stage-fadeInFailed 0s .7s linear forwards;
}

.build-pipeline__status-bar--failed .build-pipeline__animation-circle:after {
  content: "";
}

.build-pipeline__status-bar--not-executed .build-pipeline__animation-line:before,
.build-pipeline__status-bar--not-executed .build-pipeline__circle-clip1:before,
.build-pipeline__status-bar--not-executed .build-pipeline__circle-clip2:before,
.build-pipeline__status-bar--not-executed .build-pipeline__circle-inner-fill {
  background-color: #d1d1d1;
}

.build-pipeline__status-bar--not-executed .build-pipeline__animation-circle {
  animation: pipeline-stage-fadeInNotExecuted 0s .7s linear forwards;
}

.build-pipeline__status-bar--not-executed .build-pipeline__animation-circle:after {
  content: "";
}

.build-pipeline__status-bar--paused-pending-input .build-pipeline__animation-line:before,
.build-pipeline__status-bar--paused-pending-input .build-pipeline__circle-clip1:before,
.build-pipeline__status-bar--paused-pending-input .build-pipeline__circle-clip2:before,
.build-pipeline__status-bar--paused-pending-input .build-pipeline__circle-inner-fill {
  background-color: #f0ab00;
}

.build-pipeline__status-bar--paused-pending-input .build-pipeline__animation-circle:after {
  content: "";
  font-size: 10px;
}

.build-pipeline__status-bar--aborted .build-pipeline__animation-line:before,
.build-pipeline__status-bar--aborted .build-pipeline__circle-clip1:before,
.build-pipeline__status-bar--aborted .build-pipeline__circle-clip2:before,
.build-pipeline__status-bar--aborted .build-pipeline__circle-inner-fill {
  background-color: #d1d1d1;
}

.build-pipeline__status-bar--aborted .build-pipeline__animation-circle {
  animation: pipeline-stage-fadeInAborted 0s .7s linear forwards;
}

.build-pipeline__status-bar--aborted .build-pipeline__animation-circle:after {
  content: "";
}

.build-pipeline__status-bar--in-progress .build-pipeline__animation-line:before,
.build-pipeline__status-bar--in-progress .build-pipeline__circle-clip1:before,
.build-pipeline__status-bar--in-progress .build-pipeline__circle-clip2:before,
.build-pipeline__status-bar--in-progress .build-pipeline__circle-inner-fill {
  background-color: #0088ce;
}

.build-pipeline__status-bar--in-progress .build-pipeline__animation-circle {
  animation: pipeline-stage-fadeInInProgress 0s .7s linear forwards;
}

.build-pipeline__status-bar--in-progress .build-pipeline__animation-circle:after {
  content: "";
}

.build-pipeline__status-bar--in-progress .build-pipeline__animation-line {
  overflow: hidden;
}

.build-pipeline__status-bar--in-progress .build-pipeline__animation-line:before {
  animation: build-progress-rail 5s .5s linear infinite;
  background-color: #0088ce;
  transform: translateX(-100%);
  width: 25%;
}

.build-pipeline__status-bar--in-progress .build-pipeline__circle-clip1:before,
.build-pipeline__status-bar--in-progress .build-pipeline__circle-clip2:before,
.build-pipeline__status-bar--in-progress .build-pipeline__circle-inner-fill {
  background-color: #0088ce;
}

.build-pipeline__status-bar--in-progress .build-pipeline__animation-circle {
  animation: pipeline-stage-fadeInProgress 0s .7s linear forwards;
}

.build-pipeline__status-bar--in-progress .build-pipeline__animation-circle:after {
  content: "\f021";
}

.build-pipeline__status-bar {
  align-items: center;
  display: flex;
  flex-direction: column;
  margin-bottom: -9px;
}

.build-pipeline__log-link,
.build-pipeline__timestamp {
  font-size: 11px;
}

.build-pipeline__animation-line {
  background: #d1d1d1;
  height: 4px;
  position: relative;
  width: 100%;
}

.build-pipeline__animation-line:before {
  animation: build-progress-line .35s ease-in forwards;
  content: '';
  height: 100%;
  position: absolute;
  width: 0;
}

.build-pipeline__animation-circle {
  background: #d1d1d1;
  border-radius: 9px;
  height: 18px;
  margin-top: -11px;
  position: relative;
  transform: rotate(-90deg);
  width: 18px;
}

.build-pipeline__animation-circle:after {
  animation: pipeline-stage-fadeIcon .1s .875s linear forwards;
  color: #fff;
  font-family: 'FontAwesome';
  font-size: 12px;
  left: 50%;
  opacity: 0;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%) rotate(90deg);
}

.build-pipeline__circle-clip1,
.build-pipeline__circle-clip2 {
  position: absolute;
  z-index: -9;
}

.build-pipeline__circle-clip1:before,
.build-pipeline__circle-clip2:before {
  border-radius: 9px;
  content: '';
  height: 18px;
  position: absolute;
  transform: rotate(0);
  width: 18px;
}

.build-pipeline__circle-clip1 {
  clip: rect(0, 18px, 18px, 9px);
}

.build-pipeline__circle-clip1:before {
  animation: build-progress .175s .35s linear forwards;
  clip: rect(0, 9px, 18px, 0);
}

.build-pipeline__circle-clip2 {
  clip: rect(0, 9px, 18px, 0);
}

.build-pipeline__circle-clip2:before {
  animation: build-progress .175s .525s linear forwards;
  clip: rect(0, 18px, 18px, 9px);
}

.build-pipeline__circle-inner {
  animation: pipeline-stage-fadeOut .1s .7s linear forwards;
  background-color: #fff;
  border-radius: 9px;
  height: 10px;
  left: 4px;
  position: absolute;
  top: 4px;
  width: 10px;
}

.build-pipeline__circle-inner-fill {
  border-radius: 50%;
  box-sizing: border-box;
  height: 100%;
  opacity: 0;
  width: 100%;
}

.table-bar {
  margin-top: 5px;
  height: 4px;
  color: #fff;
  transition: width 0.6s ease;
  background-color: #cccccc;
}

.table-bar--active {
  float: right;
  background-color: rgba(82,163,217,0.5);
  animation: progress-bar-stripes 1s linear infinite;
}

.group {
  margin-bottom: 15px;
}

.group__title {
  border: 1px solid #ccc;
  background-color: #f5f5f5;
  display: flex;
  justify-content: space-between;
  padding: 10px 15px;
}

@media (max-width: 480px) {
  .group__title {
    flex-direction: column;
  }
}

.group__title h2 {
  margin: 0;
}

.group__body {
  border: 1px solid #ccc;
  border-top: none;
}

.group__body--filter-bar {
  margin-top: -30px;
  padding-top: 30px;
}

.group__documentation {
  padding-bottom: 12px;
  padding-top: 12px;
}

.group__additional-support {
  padding-top: 12px;
}

.group__graphs {
  overflow: hidden;
}

.cluster-overview-cell {
  margin-top: -15px;
}

@media (min-width: 1200px) {
  .cluster-overview-cell .col-lg-8 {
    padding-right: 0;
  }
}

.cluster-overview-cell__title {
  font-size: 16px;
  margin-top: 0;
  margin-bottom: 18px;
  line-height: 25px;
}

.cluster-overview-cell__info-row {
  border-bottom: solid 1px #ccc;
  padding: 12px 0;
  line-height: 20px;
}

.cluster-overview-cell__info-row:last-child {
  border-bottom: none;
}

.cluster-overview-cell__info-row__first-cell {
  margin-left: -5px;
}

.cluster-overview-cell__info-row__last-cell {
  margin-right: -5px;
  text-align: right;
}

.ldap-group {
  padding-top: 10px;
}

.certs-info-cert {
  padding-bottom: 5px;
}

.certs-info-cert .co-resource-link .co-m-resource-icon {
  margin: 0 4px 0 0;
}

.alert-manager-list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.alert-manager-row {
  flex: 0 1 50%;
}

.co-cluster-updates-warning {
  max-width: 800px;
}

.co-cluster-updates {
  border: 1px solid #ccc;
  max-width: 800px;
}

.co-cluster-updates__operator-icon--complete,
.co-cluster-updates__operator-icon--up-to-date,
.co-cluster-updates__operator-ts--completed,
.co-cluster-updates--update-available,
.co-cl-operator--downloaded,
.co-cl-operator--up-to-date {
  color: #2ec98e;
}

.co-cluster-updates__operator-icon--needsattention,
.co-cluster-updates--needs-attention,
.co-cl-operator--pending {
  color: #fca657;
}

.co-cluster-updates__operator-icon--failed,
.co-cluster-updates__operator-ts--failed,
.co-cluster-updates__operator-ts--backoff,
.co-cluster-updates--failed {
  color: #D64456;
}

.co-cluster-updates__operator-icon--pending,
.co-cluster-updates__operator-ts--notstarted,
.co-cl-operator--pending {
  color: #777;
}

.co-cluster-updates__operator-icon--updating,
.co-cluster-updates__operator-icon--paused,
.co-cluster-updates--updating,
.co-cluster-updates__operator-ts--running,
.co-cl-operator-spinner--downloading,
.co-cl-operator-spinner--rebooting {
  color: #52a3d9;
}

.co-cluster-updates__text-icon {
  margin-right: 4px;
}

.co-cluster-updates__action-button {
  display: block;
  margin-top: 5px;
}

.co-cluster-updates__component {
  border-bottom: 1px solid #ccc;
  margin-bottom: -1px;
  padding: 20px;
}

.co-cluster-updates__heading {
  font-size: 13px;
  justify-content: space-between;
  line-height: 13px;
}

.co-cluster-updates__heading--name-wrapper {
  min-width: 40%;
  padding-right: 10px;
}

.co-cluster-updates__heading--name {
  font-size: 16px;
  font-weight: 600;
}

.co-cluster-updates__heading--version {
  color: #777;
  margin-left: 0.75em;
}

.co-cluster-updates__heading--updates {
  flex: 1 0 0;
}

.co-cluster-updates__details {
  display: flex;
  flex-direction: row;
  font-size: 16px;
  margin: 18px 0 0 0;
}

.co-cluster-updates__detail {
  flex: 2 1 auto;
  padding: 0 5px;
}

.co-cluster-updates__operator {
  display: flex;
  flex-direction: row;
  margin: 30px 0 10px 0;
}

.co-cluster-updates__operator-component {
  flex: 1 1 auto;
}

.co-cluster-updates__operator-step {
  display: flex;
  flex-direction: row;
  margin-bottom: 5px;
}

.co-cluster-updates__operator-step:last-of-type {
  margin-bottom: 0;
}

.co-cluster-updates__operator-details {
  padding-left: 25px;
}

.co-cluster-updates__operator-details button {
  padding-top: 0;
}

.co-cluster-updates__operator-show-details {
  padding: 0 0 20px 0;
}

.co-cluster-updates__operator-logs {
  margin-bottom: 20px;
}

.co-cluster-updates__operator-header {
  font-weight: 600;
}

.co-cluster-updates__operator-icon {
  min-width: 22px;
  width: 25px;
}

.co-cluster-updates__operator-text {
  flex-grow: 1;
}

.co-cluster-updates__operator-button {
  margin-bottom: 15px;
  padding: 7px 16px 4px;
}

.co-cluster-updates__operator-ts-error-msg {
  border-top: 1px solid #D64456;
  color: #D64456;
  font-size: 11px;
  margin: 5px 0 0 25px;
  padding-top: 5px;
}

.co-cluster-updates__operator-ts-error-msg-link {
  margin-left: 25px;
  font-size: 12px;
}

.co-cluster-updates__operator-ts-component {
  margin-top: 10px;
}

.co-cluster-updates__operator-ts-step {
  display: flex;
  flex-direction: row;
}

.co-cluster-updates__message-box {
  margin: 15px 0 0 5px;
  padding: 10px;
  overflow: auto;
}

.co-cluster-updates__message-box--failed {
  background-color: rgba(214,68,86,0.1);
  border: 1px solid #D64456;
  color: #D64456;
}

.co-cluster-updates__message-box--warning {
  background-color: #fca657;
  border: 1px solid #fca657;
  color: #fca657;
}

.co-cluster-updates__operator-subheader {
  font-weight: 600;
}

.co-cluster-channel-dropdown {
  height: 120px;
}

.co-cluster-channel-dropdown>.dropdown>.btn--dropdown {
  width: 250px;
}

.co-cluster-channel-dropdown>.dropdown>.dropdown-menu {
  width: 250px;
}

.co-cluster-updates__sec-appversion-ts {
  border-left: 1px solid #ccc;
  border-image: linear-gradient(#eee, #ccc, #eee);
  border-image-slice: 1;
  margin-left: 22px;
}

.co-cluster-updates__operator-text--running {
  border-bottom: 1px solid #ccc;
  padding-bottom: 5px;
}

.co-cluster-updates__appversion-ts {
  padding-right: 0;
}

.co-p-configure-ports__port-number {
  max-width: 100% !important;
}

.co-detail-table {
  text-align: center;
  border-width: 1px;
  max-width: 600px;
  white-space: nowrap;
}

.co-detail-table__row {
  margin-left: 0;
  margin-right: 0;
}

.co-detail-table,
.co-detail-table__section {
  border-color: #eee;
  border-style: solid;
}

.co-detail-table__section {
  display: flex;
  align-items: center;
  justify-content: center;
  border-width: 1px 1px 0 0;
  padding: 20px 10px;
  margin-top: -1px;
  margin-right: -1px;
}

.co-detail-table__section--last {
  border-right-width: 0;
}

.co-detail-table__section-header {
  margin-top: 4px;
  margin-bottom: 4px;
}

.co-detail-table__bracket {
  width: 12px;
  height: 48px;
  background: url("./imgs/bracket.svg") center center no-repeat;
  background-size: 12px 48px;
  padding: 0 20px;
}

.co-detail-table__breakdown {
  display: flex;
  flex-direction: column;
  font-size: 14px;
  justify-content: space-between;
  height: 47px;
  text-align: left;
}

.dropdown {
  position: relative;
}

.dropdown .co-m-resource-icon {
  margin-left: 2px;
}

.dropdown__disabled {
  color: #9c9c9c;
  cursor: not-allowed;
}

.dropdown__not-btn {
  cursor: pointer;
}

.dropdown-menu__divider {
  height: 1px;
  margin: 9px 0;
  overflow: hidden;
  flex-grow: 1;
  background-color: #e5e5e5;
}

.dropdown-menu__header {
  color: #777;
  flex-grow: 1;
  font-size: 11px;
  padding: 3px 10px;
  text-transform: uppercase;
}

.dropdown-menu__filter {
  padding: 5px 10px 10px;
}

.dropdown-menu--text-wrap {
  max-width: 100%;
}

.dropdown-menu--text-wrap li a {
  min-width: 0;
  overflow-wrap: break-word;
  white-space: normal;
  word-wrap: break-word;
}

.dropdown--text-filter {
  float: none !important;
}

.favorite {
  color: gold;
}

.dropdown-menu {
  display: block;
}

.dropdown-menu ul {
  padding: 0;
  margin: 0;
  list-style: none;
}

.dropdown-menu li {
  padding: 0;
  display: flex;
}

.dropdown-menu li:focus {
  outline: none;
}

.dropdown-menu li a {
  cursor: pointer;
  flex-grow: 1;
  width: 100%;
}

.dropdown-menu li a.next-to-bookmark {
  padding-left: 5px;
}

.dropdown-menu li a.hover {
  background-color: #def3ff;
  border-top: 1px solid #bee1f4;
  border-bottom: 1px solid #bee1f4;
  color: #4d5258;
  position: relative;
}

.dropdown-menu li a.hover:after,
.dropdown-menu li a.hover:before {
  height: 1px;
}

.dropdown-menu li a.focus {
  color: white;
}

.dropdown--dark {
  background-color: #212121;
  border: solid 1px #212121;
}

.dropdown--dark li .dropdown-menu__divider {
  background-color: #292e34;
}

.dropdown--dark li>a {
  color: white;
}

.dropdown--dark li>a:hover {
  background-color: rgba(255,255,255,0.15);
  color: white;
}

.co-namespace-selector {
  background-color: #292E34;
  color: #d1d1d1;
  display: flex;
  font-size: 14px;
  height: 27px;
  padding-right: 15px;
  white-space: nowrap;
}

@media (min-width: 768px) {
  .co-namespace-selector {
    border-left: 1px solid #050505;
    font-size: 16px;
    height: 40px;
  }
}

.co-namespace-selector__dropdown {
  max-width: 95%;
}

.co-namespace-selector .dropdown__not-btn {
  align-items: baseline;
  display: flex;
  padding: 2px 15px;
}

@media (min-width: 768px) {
  .co-namespace-selector .dropdown__not-btn {
    padding: 7px 15px;
  }
}

.co-namespace-selector .dropdown__not-btn__title {
  flex: 0 1 auto;
  margin-left: 10px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.co-namespace-selector__menu.dropdown-menu {
  left: 15px;
  margin-top: 0;
  max-height: calc(100vh - (40px + 27px));
  max-width: 100%;
  min-width: 210px;
  overflow-y: auto;
}

@media (min-width: 768px) {
  .co-namespace-selector__menu.dropdown-menu {
    max-height: calc(100vh - (60px + 40px));
    min-width: 250px;
  }
}

.co-namespace-selector__menu.dropdown-menu a {
  white-space: nowrap;
  width: auto;
}

.co-namespace-selector__menu.dropdown-menu .dropdown__selected {
  background-color: inherit;
  color: inherit;
}

.co-namespace-selector__menu.dropdown-menu li:not(.co-namespace-selector__divider):after,
.co-namespace-selector__menu.dropdown-menu li:not(.co-namespace-selector__divider):before {
  content: '';
  background-color: #bee1f4;
  left: 0;
  position: absolute;
  width: 100%;
}

.co-namespace-selector__menu.dropdown-menu li:not(.co-namespace-selector__divider):after {
  top: 0;
}

.co-namespace-selector__menu.dropdown-menu li:not(.co-namespace-selector__divider):before {
  bottom: 0;
}

.co-namespace-selector__menu.dropdown-menu li:not(.co-namespace-selector__divider):hover {
  background-color: #def3ff;
  color: #4d5258;
  position: relative;
}

.co-namespace-selector__menu.dropdown-menu li:not(.co-namespace-selector__divider):hover:after,
.co-namespace-selector__menu.dropdown-menu li:not(.co-namespace-selector__divider):hover:before {
  height: 1px;
}

.co-namespace-selector__menu.dropdown-menu li:not(.co-namespace-selector__divider).active:hover:after,
.co-namespace-selector__menu.dropdown-menu li:not(.co-namespace-selector__divider).active:hover:before {
  height: 0;
}

.co-namespace-selector__menu.dropdown-menu li>a {
  padding: 6px;
  text-decoration: none;
}

.co-namespace-selector__menu.dropdown-menu li>a.active {
  border-color: transparent;
}

.co-namespace-selector__menu.dropdown-menu li>a:active {
  background-color: transparent;
  color: inherit !important;
}

.co-namespace-selector__menu.dropdown-menu li>a.bookmarker {
  display: inline-block;
  padding-right: 5px;
  padding-left: 12px;
  color: #DDD;
  flex-grow: 0;
}

.co-namespace-selector__menu.dropdown-menu li>a.bookmarker:hover {
  background-color: transparent;
  color: #AAA;
}

.co-namespace-selector__menu.dropdown-menu li>a.next-to-bookmark {
  overflow: hidden;
  text-overflow: ellipsis;
}

.co-namespace-selector__menu.dropdown-menu li.dropdown__selected>a {
  color: #52a3d9;
}

.co-namespace-selector__menu.dropdown-menu li.dropdown__selected>a.bookmarker {
  color: #DDD;
}

.co-namespace-selector__menu.dropdown-menu li.dropdown__selected>a.bookmarker:hover {
  color: #AAA;
}

.co-namespace-selector__menu.dropdown-menu li.dropdown__selected>a.selected {
  color: #52a3d9;
}

.btn--dropdown {
  max-width: 100%;
}

.btn--dropdown .caret {
  flex: 0 0 auto;
  margin-left: 5px;
}

.btn--dropdown__item {
  overflow: hidden;
  text-align: left;
  text-overflow: ellipsis;
}

.btn--dropdown__item .co-resource-link__resource-name {
  overflow: hidden;
  text-overflow: ellipsis;
}

.btn--dropdown__content-wrap {
  align-items: center;
  display: flex;
  flex: 1 1 auto;
  justify-content: space-between;
  min-width: 0;
}

.btn--actions {
  margin-top: -1px;
}

.co-global-notification {
  background-color: #0088ce;
  color: #fff;
  text-align: center;
}

.co-global-notification a {
  color: #fff;
  cursor: pointer;
  text-decoration: underline;
}

.co-global-notification__text {
  margin: 0;
}

.co-global-notification__impersonate-name {
  font-weight: 600;
}

.co-global-notification__impersonate-kind {
  font-weight: 500;
  padding-right: 20px;
}

.btn-unselected:hover {
  color: #52a3d9;
}

.btn-selected {
  background-color: #52a3d9;
  color: white;
}

.btn-selected:hover {
  background-color: #2276AD;
  color: white;
}

.co-m-table-grid__head {
  color: #666;
  text-transform: uppercase;
}

.co-m-table-grid__sort-link--unsorted {
  color: #666;
}

.co-m-table-grid__sort-arrow {
  margin-left: 10px;
}

.co-resource-list__item {
  height: 100%;
  transition: all 0.25s;
}

.compaction-btn {
  margin-right: 0 !important;
}

.co-m-cog__dropdown {
  margin-bottom: 10px;
}

.co-m-nav-title {
  padding: 30px 15px 0;
}

@media (min-width: 768px) {
  .co-m-nav-title {
    padding-left: 30px;
    padding-right: 30px;
  }
}

.co-m-nav-title--breadcrumbs {
  padding-top: 0;
}

.co-m-nav-title--detail {
  border-bottom: 1px solid #ccc;
  background-color: #f5f5f5;
}

.co-m-nav-title--logo {
  padding-top: 15px;
}

.co-node-ip {
  margin: 0;
  padding: 0;
  line-height: 14px;
}

.co-node-ip p {
  margin: 0;
  padding: 5px 0;
}

.co-ip-type {
  color: #999;
}

.co-ip-addr {
  color: black;
}

.co-m-overflow {
  position: relative;
}

.co-m-overflow__gradient {
  background: linear-gradient(to left, #fff 0%, #fff 31%, rgba(255,255,255,0) 100%);
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
  pointer-events: none;
  width: 35px;
}

.co-m-overflow__input:active ~ .co-m-overflow__gradient,
.co-m-overflow__input:focus ~ .co-m-overflow__gradient {
  display: none;
}

.co-m-overflow__link {
  overflow: hidden;
  text-overflow: ellipsis;
  display: flex;
}

.rbac-rules-list {
  margin: 30px 0;
}

.rbac-rule__actions {
  align-items: baseline;
  display: flex;
}

.rbac-rule__cog {
  margin-right: 5px;
}

.rbac-rule-resource {
  align-items: baseline;
  display: inline-flex;
  margin-right: 20px;
  min-width: 0;
}

.rbac-rule-resource__label {
  overflow-wrap: break-word;
  word-wrap: break-word;
  min-width: 0;
}

.rbac-rule-resource .co-m-resource-icon {
  flex-shrink: 0;
}

.rbac-rule-resources {
  display: flex;
  flex-wrap: wrap;
}

.rbac-rule-row:not(:last-of-type) {
  padding-bottom: 5px;
}

.resource-separator {
  margin-top: 10px;
  margin-bottom: 10px;
}

.edit-rule .rule-row {
  margin-top: 30px;
}

.edit-rule .help-text {
  color: #666;
  font-weight: 400;
  position: relative;
  top: -3px;
  left: 20px;
}

.edit-rule .checkbox-label {
  margin-bottom: 5px;
  font-weight: 400;
}

.edit-rule .rbac-minor {
  margin-top: 10px;
  margin-bottom: 20px;
  color: #ccc;
}

.edit-rule .rbac-major {
  color: #ccc;
  margin: 30px 0;
}

.edit-rule .newspaper-columns {
  columns: 3 auto;
  margin-top: 20px;
}

.edit-rule .text-input {
  max-width: 500px;
  margin-top: 20px;
}

.rbac-edit-binding .alert {
  margin-top: 20px;
}

.rbac-edit-binding .btn--dropdown {
  max-width: 400px;
  width: 100%;
}

.rbac-edit-binding .btn-group {
  width: 100%;
}

.rbac-edit-binding .form-control {
  max-width: 400px;
}

.rbac-edit-binding .dropdown {
  max-width: 400px;
}

@media (min-width: 768px) {
  .rbac-edit-binding .dropdown-menu {
    min-width: 400px;
  }
}

.rbac-edit-binding .rbac-edit-binding__input-label {
  margin-bottom: 5px;
}

.rbac-edit-binding .radio-item {
  margin: 10px 0;
}

.rbac-edit-binding .radio-item .co-m-radio-desc {
  margin-left: 19px;
}

.rbac-edit-binding .separator {
  border-top: solid 1px #eee;
  margin: 30px 0;
}

.co-m-resource-icon {
  display: inline-block;
  font-size: 12px;
  font-weight: normal;
  text-align: center;
  border-radius: 10px;
  height: 18px;
  min-width: 18px;
  padding: 0 4px;
  line-height: 18px;
  color: #fff;
  margin: 0 4px;
  background-color: #00b9e4;
}

.co-m-resource-icon--lg {
  border-radius: 12px;
  font-size: 16px;
  height: 24px;
  line-height: 24px;
  margin-left: 0;
  margin-right: 7px;
  min-width: 24px;
  padding: 0 7px;
  position: relative;
  top: 4px;
}

.co-resource-icon--fixed-width {
  display: flex;
  justify-content: center;
  text-align: center;
  width: 40px;
}

.co-m-resource-clusterrole,
.co-m-resource-role {
  background-color: #773d00;
}

.co-m-resource-clusterrolebinding,
.co-m-resource-rolebinding {
  background-color: #005c66;
}

.co-m-resource-daemonset,
.co-m-resource-deployment,
.co-m-resource-deploymentconfig,
.co-m-resource-job,
.co-m-resource-petset,
.co-m-resource-replicaset,
.co-m-resource-replicationcontroller {
  background-color: #004368;
}

.co-m-resource-node,
.co-m-resource-policy {
  background-color: #703fec;
}

.co-m-resource-configmap {
  background-color: #40199a;
}

.co-m-resource-container {
  background-color: #00b9e4;
}

.co-m-resource-pod {
  background-color: #007a87;
}

.co-m-resource-namespace,
.co-m-resource-project {
  background-color: #2d7623;
}

.co-m-resource-secret {
  background-color: #ec7a08;
}

.co-m-resource-alertmanager {
  background-color: #b35c00;
}

.co-m-resource-service {
  background-color: #6ca100;
}

.co-m-resource-serviceaccount {
  background-color: #40199a;
}

.co-m-resource-ingress {
  background-color: #1f0066;
}

.co-m-resource-icon--all {
  background-color: black;
}

.co-m-resource-icon--align-left {
  margin-left: 0;
}

.co-m-resource-icon--flex-child {
  margin-right: 7px;
}

.co-resource-link {
  align-items: baseline;
  display: flex;
  min-width: 0;
  overflow-wrap: break-word;
  word-wrap: break-word;
}

.co-resource-link--inline {
  display: inline-flex;
  margin: 0 20px 0 0;
}

.co-resource-link__resource-name {
  min-width: 0;
}

.co-resource-link .co-m-resource-icon {
  flex-shrink: 0;
}

.co-resource-link-wrapper {
  display: flex;
}

.co-m-selector {
  overflow-wrap: break-word;
  word-wrap: break-word;
}

.co-type-selector {
  margin-right: -2px;
  min-width: 0;
}

.co-type-selector .dropdown-menu {
  max-height: 75vh;
  min-width: 290px;
  overflow-x: hidden;
  overflow-y: auto;
}

.co-type-selector .dropdown-menu a {
  padding: 4px 20px 4px 6px;
}

.row-filter {
  outline: 1px solid #DDD;
  background-color: #f5f5f5;
  margin-bottom: 30px;
  margin-top: 3px;
}

.row-filter--box {
  display: inline-block;
  padding: 7px 10px;
  background: #f5f5f5;
  outline: 1px solid #DDD;
  position: relative;
}

.row-filter--box__active {
  outline: 1px solid #51A3D9;
  background: #E0F1FD;
  z-index: 1;
}

.row-filter--number-bubble {
  background: white;
  border-radius: 4px;
  padding: 0 5px;
  padding-top: 2px;
  line-height: 16px;
  border: 1px solid #DDD;
  display: inline-block;
  text-align: center;
  font-size: 14px;
  margin-right: 3px;
}

.row-filter--box__empty {
  color: #999;
}

.row-filter--box__empty .row-filter--number-bubble {
  background: inherit;
}

.co-m-route-status-icon {
  padding-right: 6px;
  width: 16px;
}

.co-m-route-tls-reveal__title {
  display: flex;
  justify-content: space-between;
}

.co-m-route-tls-reveal__btn {
  padding-right: 0;
}

.co-m-route-ingress-status+.co-m-route-ingress-status {
  margin-top: 40px;
}

.co-create-secret-form__textarea {
  min-height: 300px;
  width: 100%;
}

.service-ips .co-ip-header {
  padding: 10px 0 5px 20px;
}

.service-ips .co-ip-row {
  margin-top: -1px;
  padding: 20px 0 5px 20px;
  border: 1px solid #eee;
}

.service-ips .co-ip-row .ip-name {
  margin-bottom: 5px;
}

.co-sysevent-icon {
  background-color: #fff;
  border: solid 3px #eee;
  border-radius: 50%;
  color: #fff;
  position: relative;
  z-index: 10;
  height: 18px;
  width: 18px;
}

.co-sysevent--error .co-sysevent-icon,
.co-sysevent--error .co-sysevent__box {
  border-color: #d64456;
}

.co-sysevent--error .co-sysevent__icon-line {
  background-color: #d64456;
}

.co-sysevent-stream {
  padding: 60px 0 50px 0;
  position: relative;
}

.slide-entering {
  left: 100%;
  opacity: 0;
}

.slide-entered {
  left: 0;
  opacity: 1;
  transition: all 0.5s;
}

.co-sysevent-stream__connection-error {
  color: #D64456;
}

.co-sysevent-stream__timeline {
  bottom: 30px;
  position: absolute;
  top: 35px;
}

.co-sysevent-stream__status {
  display: flex;
  justify-content: space-between;
  position: absolute;
  top: 9px;
  left: 40px;
  right: 4px;
}

@media (min-width: 768px) {
  .co-sysevent-stream__status {
    padding-left: 25px;
    left: 45px;
    right: 0;
  }
}

.co-sysevent-stream__status-box-empty {
  border-style: solid;
  border-color: #eee;
  border-width: 1px 0;
}

.co-sysevent-stream__timeline__end-message {
  bottom: 0;
  position: absolute;
  transform: translateY(50%);
  white-space: nowrap;
  z-index: 1;
}

.co-sysevent-stream__timeline--empty .co-sysevent-stream__timeline__end-message {
  display: none;
}

.co-sysevent-stream__timeline__btn {
  margin: 0;
  transform: translate(0%, -100%);
  margin-left: 2px;
}

.co-sysevent {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  height: 120px;
}

.co-sysevent .co-m-resource-icon {
  margin-left: 0;
}

.co-sysevent__box {
  padding: 10px;
  background-color: #fff;
  border: solid 1px #ddd;
  border-bottom-color: #eee;
  width: 100%;
  flex: none;
}

.co-sysevent__details {
  display: flex;
  justify-content: space-between;
}

.co-sysevent__resourcelink {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.co-sysevent__subheader {
  display: flex;
  justify-content: space-between;
  white-space: pre;
}

.co-sysevent__source {
  flex: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.co-sysevent__message {
  overflow-wrap: break-word;
  word-wrap: break-word;
  cursor: help;
  margin-top: 10px;
  overflow: hidden;
  text-overflow: ellipsis;
  height: 40px;
  position: relative;
}

.co-sysevent__message:after {
  background: linear-gradient(to right, rgba(255,255,255,0), #fff 50%);
  bottom: 0;
  content: "";
  height: 25px;
  position: absolute;
  right: 0;
  width: 15%;
  text-align: right;
}

.co-sysevent__icon-box {
  flex: 0 0 100px;
  display: none;
  position: relative;
}

.co-sysevent__icon-box .co-sysevent-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(calc(50% - 17px), calc(-50% + 1px));
}

.co-sysevent__icon-line {
  background-color: #eee;
  height: 3px;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 50%;
}

@media (min-width: 508px) {
  .co-sysevent {
    height: 95px;
  }

  .co-sysevent__message {
    height: 25px;
  }
}

@media (min-width: 768px) {
  .co-sysevent {
    flex-wrap: nowrap;
    margin-left: -40px;
  }

  .co-sysevent__box {
    padding: 10px 10px 0 10px;
    flex: 1 2 auto;
    border: solid 1px #eee;
    min-width: 0%;
  }

  .co-sysevent__icon-box {
    display: block;
  }

  .co-sysevent-stream {
    padding-top: 50px;
    padding-left: 10px;
  }

  .co-sysevent-stream__timeline {
    border-bottom: 3px solid #eee;
    border-left: 3px solid #eee;
    margin-left: 10px;
  }

  .co-sysevent-stream__timeline--empty {
    border: 0;
  }

  .co-sysevent-stream__timeline__btn {
    transform: translate(-50%, -100%);
    margin-left: -2px;
  }

  .co-sysevent-stream__timeline__end-message {
    left: 45px;
  }
}

.co-icon-and-text {
  align-items: baseline;
  display: flex;
}

.co-icon-and-text__icon {
  margin-right: 5px;
}

.co-toggle-play {
  background-color: #fff;
  border: 3px solid #eee;
  border-radius: 50%;
  height: 32px;
  margin-right: 15px;
  padding: 0;
  width: 32px;
}

.co-toggle-play.co-toggle-play--inactive:before {
  content: "";
}

.co-toggle-play.co-toggle-play--active:before {
  content: "";
}

.co-toggle-play.co-toggle-play--active {
  border-color: #68C28D;
}

.co-m-table-grid__head {
  padding: 0 0 10px 0;
}

.co-m-pane__body:last {
  padding-bottom: 30px;
}

.co-m-volume-icon .fa {
  margin: 0 4px;
  text-align: center;
  vertical-align: middle;
  width: 14px;
}

.co-m-vert-nav {
  display: flex;
  flex-direction: column;
}

.co-m-vert-nav__menu {
  border-bottom: 1px solid #ccc;
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  margin: 0;
  padding: 0;
}

@media (min-width: 768px) {
  .co-m-vert-nav__menu {
    margin-left: -17px;
    padding: 0 30px;
  }
}

.co-m-vert-nav__menu-item {
  font-size: 18px;
  margin: 0;
  text-align: center;
}

.co-m-vert-nav__menu-item.co-m-vert-nav-item--active a {
  color: #52a3d9;
  position: relative;
}

.co-m-vert-nav__menu-item.co-m-vert-nav-item--active a::after {
  background: #52a3d9;
  bottom: 0;
  content: "";
  display: block;
  height: 2px;
  left: 15px;
  position: absolute;
  right: 15px;
}

@media (min-width: 768px) {
  .co-m-vert-nav__menu-item.co-m-vert-nav-item--active a::after {
    left: 17px;
    right: 17px;
  }
}

.co-m-vert-nav__menu-item a {
  color: inherit;
  display: block;
  text-decoration: none;
  padding: 6px 15px 8px;
}

@media (min-width: 768px) {
  .co-m-vert-nav__menu-item a {
    padding-left: 17px;
    padding-right: 17px;
  }
}

.co-m-vert-nav__menu-item--divider {
  border-left: 1px solid #ccc;
  margin: 0 10px;
}

.yaml-editor-header {
  border-bottom: 1px solid #ddd;
  font-size: 16px;
  height: 45px;
  padding: 10px 20px;
}

.yaml-editor {
  position: relative;
}

.yaml-editor--flexbox {
  display: flex;
  flex-direction: column;
}

.yaml-editor--acebox {
  flex: 1;
}

.yaml-editor--buttons {
  background: white;
  border-top: 1px solid #ddd;
  padding: 20px 15px;
  margin-top: auto;
  z-index: 10;
}

@media (min-width: 768px) {
  .yaml-editor--buttons {
    padding-left: 30px;
    padding-right: 30px;
  }
}

.co-overlay {
  background: rgba(0,0,0,0.5);
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 1050;
}

.ReactModal__Content .fa-times-circle:hover {
  cursor: pointer;
  color: #000;
}

.ReactModal__Content--after-open {
  -webkit-transform: translate(0, 0);
  -ms-transform: translate(0, 0);
  -o-transform: translate(0, 0);
  transform: translate(0, 0);
}

.tags-list__row {
  padding-bottom: 15px;
}

.tags-list__field {
  padding-right: 0;
}

.tags-list__heading {
  margin-bottom: 4px;
  text-transform: uppercase;
  color: #666;
}

.tags-list__btn {
  cursor: pointer;
}

.tags-list__delete-icon {
  line-height: 32px;
}

.tags-list__add-icon {
  margin-right: 6px;
}

.cluster-picker {
  background-color: #393f44;
  border-radius: 1px;
  color: #d1d1d1;
  margin: 7px 10px;
  padding: 2px 0 2px 10px;
}

.cluster-picker .dropdown .dropdown__not-btn {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  padding-right: 20px;
}

.cluster-picker .dropdown .dropdown__not-btn .caret {
  position: absolute;
  right: 9px;
  top: 6px;
}

.cluster-picker .dropdown ul.dropdown-menu {
  box-shadow: 0 2px 4px rgba(0,0,0,0.175);
  margin-left: -10px;
  margin-top: 2px;
  min-width: 200px;
}

@media (max-width: 767px) {
  .cluster-picker .dropdown ul.dropdown-menu {
    width: 200px;
  }
}

.cluster-picker .dropdown ul.dropdown-menu>li.active>a {
  background-color: #393f44 !important;
  border-color: transparent !important;
}

@media (max-width: 767px) {
  .cluster-picker .dropdown ul.dropdown-menu>li>a {
    overflow-wrap: break-word;
    white-space: normal;
    width: 100%;
    word-break: break-word;
  }
}

.cluster-picker .dropdown ul.dropdown-menu>li>a:hover {
  background-color: #4c545a;
}

.cluster-picker:hover {
  background-color: #393f44;
}

.co-resource-sidebar-header {
  margin: 0;
}

.co-resource-sidebar-list {
  padding-left: 15px;
}

.co-resource-sidebar-item {
  border-top: 1px solid #ccc;
  padding: 20px 0;
}

.co-resource-sidebar-item:first-child {
  border-top: 0;
}

.co-resource-sidebar-item__header {
  font-weight: 600;
}

.co-resource-sidebar-item__details {
  margin: 0;
  padding: 25px 0;
}

.co-resource-sidebar-item__img {
  padding: 20px 0;
}

.co-role-sidebar-subheader {
  font-weight: normal;
}

.co-clusterserviceversion-list {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -7.5px 0;
}

.co-clusterserviceversion-list__tile {
  padding: 0 7.5px 15px;
  width: 100%;
}

@media (min-width: 768px) {
  .co-clusterserviceversion-list__tile {
    width: 50%;
  }
}

@media (min-width: 992px) {
  .co-clusterserviceversion-list__tile {
    width: 33.333%;
  }
}

@media (min-width: 1920px) {
  .co-clusterserviceversion-list__tile {
    width: 25%;
  }
}

.co-clusterserviceversion-list-item {
  border: 1px solid #ccc;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 20px;
  height: 200px;
}

.co-clusterserviceversion-list-item--error {
  border: 1px solid #dd1326;
}

.co-clusterserviceversion-logo {
  display: flex;
}

.co-clusterserviceversion-logo__icon {
  background: #fff;
  width: 60px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.co-m-nav-title--logo .co-clusterserviceversion-logo__icon {
  border: 1px solid #e5e5e5;
}

.co-clusterserviceversion-logo__name {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 10px;
}

.co-clusterserviceversion-logo__name__clusterserviceversion {
  font-size: 15px;
  margin-top: 0;
  margin-bottom: 5px;
}

.co-clusterserviceversion-logo__name__provider {
  font-size: 13px;
}

.co-clusterserviceversion-list-item__actions {
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.co-clusterserviceversion-list-item__description {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding: 10px 0;
}

.co-clusterserviceversion-resource-details__compact-expand {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 20px;
}

.co-clusterserviceversion-resource-details__section--info dd {
  margin-bottom: 20px;
}

.co-clusterserviceversion-details {
  display: flex;
  flex-wrap: wrap;
}

.co-clusterserviceversion-details__section--info {
  display: flex;
  flex-direction: column;
}

@media (min-width: 768px) {
  .co-clusterserviceversion-details__section--info {
    width: 33%;
  }
}

@media (min-width: 1024px) {
  .co-clusterserviceversion-details__section--info {
    width: 25%;
  }
}

@media (min-width: 1280px) {
  .co-clusterserviceversion-details__section--info {
    width: 20%;
  }
}

@media (min-width: 1440px) {
  .co-clusterserviceversion-details__section--info {
    width: 20%;
  }
}

@media (min-width: 1920px) {
  .co-clusterserviceversion-details__section--info {
    width: 20%;
  }
}

.co-clusterserviceversion-details__section--info__item {
  margin-bottom: 15px;
}

.co-clusterserviceversion-details__section--description {
  display: flex;
  flex-direction: column;
}

@media (min-width: 768px) {
  .co-clusterserviceversion-details__section--description {
    width: 66%;
  }
}

@media (min-width: 1024px) {
  .co-clusterserviceversion-details__section--description {
    width: 75%;
  }
}

@media (min-width: 1280px) {
  .co-clusterserviceversion-details__section--description {
    width: 80%;
  }
}

@media (min-width: 1440px) {
  .co-clusterserviceversion-details__section--description {
    width: 80%;
  }
}

@media (min-width: 1920px) {
  .co-clusterserviceversion-details__section--description {
    width: 80%;
  }
}

.co-clusterserviceversion-detail__error-box {
  padding: 10px;
  border: 1px solid #dd1326;
  background: #ffe6e6;
  color: #dd1326;
  font-size: 14px;
  margin-bottom: 10px;
}

.co-catalog-install-modal .modal-header .co-clusterserviceversion-logo__icon {
  border: 1px solid #ddd;
}

.co-catalog-spinner--downloading {
  color: #52a3d9;
}

.co-catalog-details {
  display: flex;
  flex-direction: column;
}

.co-package-row {
  display: flex;
  align-items: center;
}

.co-package-row__actions {
  display: flex;
  justify-content: space-between;
}

.co-catalogsource-list__section {
  margin-bottom: 40px;
}

.co-catalogsource-list__section__packages {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 20px;
}

.co-clusterserviceversion-list__disabled-icon {
  margin: 10px;
  width: 100px;
  height: 100px;
  filter: grayscale(100%);
}

.co-spec-descriptor--resource-requirements {
  border: 1px solid #eee;
  padding: 20px 0 5px 20px;
  margin-top: 10px;
}

.step--present {
  color: #3f9c35;
}

.graph-wrapper {
  padding-top: 15px;
  margin-top: 0;
  margin-bottom: 16px;
  overflow: hidden;
}

.graph-title {
  margin: 0;
  text-align: center;
  color: black;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  line-height: 1.4;
}

.co-delete-modal-checkbox {
  padding-top: 30px;
}

.co-masthead {
  align-items: center;
  background-color: #030303;
  color: #d1d1d1;
  display: flex;
  height: 40px;
  left: 0;
  position: fixed;
  right: 0;
  z-index: 1031;
}

@media (max-width: 767px) {
  .co-masthead {
    padding-left: 50px;
  }
}

@media (min-width: 768px) {
  .co-masthead {
    height: 60px;
  }
}

.co-masthead__console-picker {
  margin-left: 10px;
}

@media (max-width: 767px) {
  .co-masthead__console-picker {
    display: none;
  }
}

.co-masthead__logo {
  align-items: center;
  display: flex;
  height: 100%;
}

@media (min-width: 768px) {
  .co-masthead__logo {
    margin-left: 20px;
    margin-right: 10px;
  }
}

.co-masthead__logo img {
  max-width: 100%;
}

.co-masthead__logo-link {
  display: flex;
  height: 100%;
}

.co-masthead__user {
  display: none;
}

@media (min-width: 768px) {
  .co-masthead__user {
    display: block;
    margin-left: auto;
    margin-right: 20px;
  }
}

.co-masthead__user .dropdown__not-btn,
.co-masthead__user .dropdown__not-btn__title {
  align-items: baseline;
  display: flex;
}

.co-masthead__user-icon {
  font-size: 14px;
  margin-right: 5px;
}

.co-masthead__username {
  font-weight: 300;
  max-width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
}

@media (min-width: 992px) {
  .co-masthead__username {
    max-width: 300px;
  }
}

.contextselector-pf .bootstrap-select>.dropdown-toggle {
  font-weight: 300;
  padding-top: 19px;
  width: 100%;
}

.contextselector-pf .bootstrap-select>.dropdown-toggle:focus {
  outline: 0 !important;
}

.contextselector-pf .bootstrap-select>.dropdown-menu {
  min-width: 100%;
}

.co-m-nav-link {
  display: block;
  float: none;
  font-size: 13px;
  line-height: 23px;
  overflow-x: hidden;
  padding: 2px 0 2px 40px;
  white-space: nowrap;
}

.co-m-nav-link a {
  background-color: inherit;
  color: #d1d1d1;
  display: inline-block;
  text-decoration: none;
  width: 100%;
}

.co-m-nav-link.active {
  padding-left: 37px;
  background-color: #383F47;
  border-left: 3px solid #39a5dc;
}

.co-m-nav-link.active a {
  color: #fff;
}

.co-m-nav-link:hover {
  background-color: #393f44;
}

.co-m-nav-link:focus {
  background-color: transparent;
}

.co-m-nav-link__external a:after {
  color: #72767b;
}

.navigation-container {
  flex: 1 1 auto;
  overflow-x: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}

.navigation-container__list {
  margin: -10px 0 10px 0;
  overflow-y: hidden;
  padding-left: 0;
  transition: max-height 0.25s;
}

.navigation-container__section,
.navigation-container__section__separator {
  border-bottom: 1px solid #050505;
}

.navigation-container__section--active {
  background-color: #393f44;
  position: relative;
}

.navigation-container__section--active:before {
  background: #39a5dc;
  content: "";
  height: 100%;
  position: absolute;
  width: 3px;
}

.navigation-container__section__separator {
  margin: 8px 20px 8px 40px;
}

.navigation-container__section__title {
  align-items: center;
  color: #d1d1d1;
  cursor: pointer;
  display: flex;
  font-size: 15px;
  line-height: 20px;
  padding: 20px 0px 20px 13px;
  width: 100%;
}

.navigation-container__section__title img {
  margin-right: 12px;
  width: 15px;
}

.navigation-container__section__title img[src^="/static/assets/routing"] {
  margin-left: -1px;
  margin-right: 10px;
  width: 18px;
}

.navigation-container__section__title span {
  display: inline-block;
  margin: 0 15px 0 11px;
}

.navigation-container__section__title__link,
.navigation-container__section__title__link:hover,
.navigation-container__section__title__link:focus {
  color: #d1d1d1;
  cursor: pointer;
  text-decoration: none;
  vertical-align: middle;
}

.navigation-container__section__title__icon {
  color: #72767b;
  margin-right: 9px;
  width: 18px;
}

.navigation-container__section__title__icon.fa {
  font-size: 15px;
}

.navigation-container__section__title__icon.fa-cog {
  font-size: 18px;
}

.navigation-container__section__title__icon.fa-folder-open-o,
.navigation-container__section__title__icon.pficon-user {
  font-size: 16px;
}

.navigation-container__section__title__icon--active {
  color: #39a5dc;
}

.environment-buttons {
  border-top: 1px solid #ddd;
  padding-top: 20px;
}

.pairs-list__row {
  padding-bottom: 15px;
}

.pairs-list__row-dragging {
  padding-bottom: 15px;
  opacity: 0;
}

.pairs-list__value-field,
.pairs-list__name-field {
  padding-top: 5px;
  padding-right: 0;
}

.pairs-list__value-pair-field {
  padding-right: 0;
  display: flex;
  flex-direction: column;
}

@media (min-width: 992px) {
  .pairs-list__value-pair-field {
    flex-direction: row;
  }
}

.pairs-list__value-ro-field {
  flex-grow: 1;
  padding-top: 5px;
}

.pairs-list__btn {
  cursor: pointer;
  display: block;
}

@media (min-width: 992px) {
  .pairs-list__btn {
    padding-right: 8px;
    display: inline;
  }
}

.pairs-list__side-btn {
  cursor: pointer;
  padding-right: 8px;
}

.pairs-list__action-divider {
  display: none;
}

@media (min-width: 992px) {
  .pairs-list__action-divider {
    display: inline;
    padding-right: 10px;
  }
}

.pairs-list__delete-icon {
  line-height: 32px;
}

.pairs-list__reorder-icon {
  line-height: 32px;
  padding-right: 10px;
  cursor: move;
}

.pairs-list__add-icon {
  margin-right: 6px;
}

.pairs-list__span-btns {
  white-space: pre;
}

.value-from {
  padding-top: 5px;
  width: 100%;
}

@media (min-width: 992px) {
  .value-from {
    width: 50%;
  }
}

.value-from .btn--dropdown {
  width: 100%;
}

.value-from .dropdown-menu {
  min-width: 250px;
  width: 100%;
}

@media (max-width: 767px) {
  .value-from__menu {
    left: auto;
    right: 0;
  }
}

.co-documentation-links__title {
  font-size: 14px;
  font-weight: 600;
  text-transform: none;
}

.co-documentation-links__description {
  margin-top: 8px;
}

.co-additional-support-links__link {
  font-size: 14px;
  font-weight: 600;
}

.co-additional-support-links__link-icon {
  margin-right: 7px;
}

.console>.terminal {
  color: #fff;
  padding: 10px;
  background-color: #030303;
}

.console>.terminal.fullscreen {
  z-index: 1050;
}

.console {
  width: 100%;
  height: 100%;
  padding: 0;
  background-color: #030303;
  position: relative;
}

.console-collapse-link {
  background: rgba(0,0,0,0.75) !important;
  color: #39a5dc;
  position: fixed;
  right: 18px;
  top: 4px;
  z-index: 1070;
}

