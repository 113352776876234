$width: 35px;

.co-m-overflow {
  position: relative;

  &__gradient {
    background: linear-gradient(to left, rgba(255,255,255,1) 0%,rgba(255,255,255,1) 31%,rgba(255,255,255,0) 100%);
    height: 100%;
    position: absolute;
    right: 0;
    top: 0;
    pointer-events: none;
    width: $width;
  }

  &__input:active ~ &__gradient, &__input:focus ~ &__gradient {
    display: none;
  }
}

.co-m-overflow__link {
  overflow: hidden;
  text-overflow: ellipsis;
  display: flex;
}
