$co-m-vert-nav__menu-item-link-padding-lr-desktop: 17px;
$co-m-vert-nav__menu-item-link-padding-lr-mobile: 15px;

.co-m-vert-nav {
  display: flex;
  flex-direction: column;
}

.co-m-vert-nav__menu {
  border-bottom: 1px solid $color-grey-background-border;
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  margin: 0;
  padding: 0;
  @media (min-width: $grid-float-breakpoint) {
    margin-left: (-$co-m-vert-nav__menu-item-link-padding-lr-desktop);
    padding: 0 30px;
  }
}

.co-m-vert-nav__menu-item {
  font-size: 18px;
  margin: 0;
  text-align: center;
  &.co-m-vert-nav-item--active a {
    color: $color-highlight-blue;
    position: relative;
    &::after {
      background: $color-highlight-blue;
      bottom: 0;
      content: "";
      display: block;
      height: 2px;
      left: $co-m-vert-nav__menu-item-link-padding-lr-mobile;
      position: absolute;
      right: $co-m-vert-nav__menu-item-link-padding-lr-mobile;
      @media (min-width: $grid-float-breakpoint) {
        left: $co-m-vert-nav__menu-item-link-padding-lr-desktop;
        right: $co-m-vert-nav__menu-item-link-padding-lr-desktop;
      }
    }
  }
  a {
    color: inherit;
    display: block;
    text-decoration: none;
    padding: 6px $co-m-vert-nav__menu-item-link-padding-lr-mobile 8px;
    @media (min-width: $grid-float-breakpoint) {
      padding-left: $co-m-vert-nav__menu-item-link-padding-lr-desktop;
      padding-right: $co-m-vert-nav__menu-item-link-padding-lr-desktop;
    }
  }
}

.co-m-vert-nav__menu-item--divider {
  border-left: 1px solid $color-grey-background-border;
  margin: 0 10px;
}
