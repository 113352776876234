/* Use this file to override styles from coreos-web and other 3rd party dependencies */
/* Going forward, please just vendor bits of CoreOS web into
   this project's build, and alter and maintain them in this project
   rather than overriding or bringing in built assets.
   The coreos-web project is no longer actively maintained */

// override patternfly's extra thin font weight for headers
h1,
.h1,
h2,
.h2 {
  font-weight: normal;
}

.text-secondary {
  color: $color-text-secondary;
}

small {
  font-size: 12px;
}

.form-control[disabled], fieldset[disabled] .form-control {
  cursor: auto;
}

.text-filter {
  width: 250px;
  min-width: 150px;
}

.co-m-primary-action {
  color: black;
  &:hover, &:visited {
    color: black;
  }
}

.no-margin {
  margin: 0 !important;
}

tags-input:focus,
tags-input .host:focus {
  outline: none;
}

tags-input .host {
  position: relative;
  margin-top: 5px;
  margin-bottom: 5px;
}
tags-input .host:active {
  outline: none;
}

tags-input .tags {
  cursor: text;
  // Style to look like .form-control
  min-height: $input-height-base; // Make inputs at least the height of their button counterpart (base line-height + padding + border)
  padding: 0 $padding-base-horizontal;
  font-size: $font-size-base;
  line-height: $co-m-label-line-height;
  color: $input-color;
  background-color: $input-bg;
  background-image: none; // Reset unusual Firefox-on-Android default style; see https://github.com/necolas/normalize.css/issues/214
  border: 1px solid $input-border;
  border-radius: $input-border-radius; // Note: This has no effect on <select>s in some browsers, due to the limited stylability of <select>s in CSS.
  @include box-shadow(inset 0 1px 1px rgba(0,0,0,.075));
  @include transition(border-color ease-in-out .15s, box-shadow ease-in-out .15s);
}
.modal-body tags-input .tags {
  min-height: 200px;
}
tags-input .tags.focused,
tags-input .tags:focus {
  outline: none;
  border-color: #66AFE9;
}
tags-input .tags .tag-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

tags-input .tags .tag-item {
  @extend .co-m-label;
  word-break: break-all; // required for Firefox, Edge
}

tags-input .tags .tag-item.selected {
  border: solid 1px;
}

tags-input .tags .tag-item .remove-button {
  margin: 0 0 0 2px;
  padding: 0;
  border: none;
  background: none;
  cursor: pointer;
  vertical-align: middle;
  font-size: 16px;
  color: #585858;
}
tags-input .tags .tag-item .remove-button:active {
  color: #fff;
}
tags-input .remove-button:active,
tags-input .remove-button:hover {
  text-decoration: none;
}
tags-input .tags .input {
  background: transparent;
  border: 1px solid transparent;
  line-height: $co-m-label-line-height;
  margin: 2px 0;
  outline: none;
  padding: 0;
  // Since iOS phone text input size is larger, remove border and margin and increase line-height
  @supports (-webkit-overflow-scrolling: touch) { // Target mobile Safari
    @media (max-width: $grid-float-breakpoint-max) { // Target phones
      border: 0;
      line-height: 24px;
      margin-bottom: 0;
      margin-top: 0;
    }
  }
}
tags-input .tags .input.invalid-tag {
  color: $red-invalid;
}
tags-input .tags .input::-ms-clear {
  display: none;
}
tags-input.ng-invalid .tags {
  border-color: $red-invalid;
}

tags-input .autocomplete {
  margin-top: 5px;
  position: absolute;
  padding: 5px 0;
  z-index: 999;
  width: 100%;
  background-color: white;
  border: 1px solid rgba(0, 0, 0, 0.2);
  -webkit-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
}
tags-input .autocomplete .suggestion-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
}
tags-input .autocomplete .suggestion-item {
  padding: 5px 10px;
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font: 16px "Helvetica Neue", Helvetica, Arial, sans-serif;
  color: black;
  background-color: white;
}
tags-input .autocomplete .suggestion-item.selected {
  color: white;
  background-color: #0097cf;
}
tags-input .autocomplete .suggestion-item.selected em {
  color: white;
  background-color: #0097cf;
}
tags-input .autocomplete .suggestion-item em {
  font: normal bold 16px "Helvetica Neue", Helvetica, Arial, sans-serif;
  color: black;
  background-color: white;
}

.clickable {
  cursor: pointer;
}

// account for collapsing space between .btn
.btn + .btn {
  margin-left: 3px;
}

.breadcrumb {
  margin-bottom: 0;
  padding-bottom: 15px;
  padding-top: 15px;
}

.modal-footer .alert {
  text-align: left;
}

// FIXME: Pass as `style` prop to `List` once this is resolved (https://github.com/bvaughn/react-virtualized/issues/876). This is for the cog menus' overflow.
.ReactVirtualized__Grid.ReactVirtualized__List {
  overflow: visible !important;
}
.ReactVirtualized__Grid__innerScrollContainer {
  overflow: visible !important;
}
