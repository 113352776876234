$color-log-window-body-bg: $color-pf-black;
$color-log-window-header-bg: $color-pf-black-900;
$color-log-window-text: $color-pf-black-150;
$font-family-log-window-body: $font-family-monospace;
$color-log-window-divider: $color-log-window-header-bg;

.log-window {
  color: $color-log-window-text;
}

.log-window__header {
  background-color: $color-log-window-header-bg;
  padding: 8px 10px 5px 10px;
}

.log-window__body {
  background-color: $color-log-window-body-bg;
  font-family: $font-family-log-window-body;
}

.log-window__scroll-pane {
  overflow: auto;
  padding-top: 10px;
}

.log-window__contents__text{
  padding-left: 10px;
  padding-right: 10px;
  white-space: pre;
  width: 0;
}

.log-window__resume-btn {
  background-color: $color-log-window-header-bg;
  color: $color-log-window-text;
  padding: 4px 10px;
}

.log-window__resume-btn:hover,
.log-window__resume-btn:focus,
.log-window__resume-btn:active,
{
  color: $color-log-window-text;
}
