$color-service-dark: $color-pf-light-green-500;

$color-pod-dark: $color-pf-cyan-400;

$color-namespace-dark: $color-pf-green-500;

$color-node-dark: $color-pf-purple-400;

$color-container-dark: $color-pf-light-blue-400;

$color-secret-dark: $color-pf-orange-400;

$color-rbac-binding-dark: $color-pf-cyan-500;
$color-rbac-role-dark: $color-pf-orange-600;

$color-configmap-dark: $color-pf-purple-600;

$color-pod-overlord: $color-pf-blue-600;

$color-controller-dark: $color-pod-overlord;
$color-replicaset-dark: $color-controller-dark;
$color-job-dark: $color-controller-dark;
$color-deployment-dark: $color-controller-dark;
$color-deploymentconfig-dark: $color-controller-dark;
$color-petset-dark: $color-controller-dark;

$color-serviceaccount-dark: $color-configmap-dark;

$color-alertmanager-dark: $color-pf-orange-500;

$color-ingress-dark: $color-pf-purple-700;

$color-grey-medium: #666;
$color-grey-text: #777;
$color-grey-border: #eee;
$color-grey-background: #f5f5f5;
$color-grey-background-border: #ccc;

$color-red-error: #D64456;
$color-orange-warning: #fca657;

$color-highlight-blue: #52a3d9;
$color-highlight-blue-dark: #2276AD;

$color-tectonic-green: #68C28D;

$color-border-brown: #947663;

$color-grey-when-selected: #e5e5e5;

$color-text-muted: $co-gray;
$color-text-secondary: $color-grey-medium;

// TODO: Set to $zindex-navbar-fixed var from Bootstrap. (We can't currently
// since Bootstrap vars are imported after this file.)
$sidebar-z-index: 1030;

// The z-index of the masthead needs to be greater than the left sidebar so
// that the context selector opens over it.
$masthead-z-index: $sidebar-z-index + 1;
$masthead-height-desktop: 60px;
$masthead-height-mobile: 40px;

$co-m-label-line-height: 19px;

// Masthead and navigation colors
$color-os-nav-title: $color-pf-black-300;
$color-os-nav-background: $color-pf-black-900;
$color-os-nav-background-active: $color-pf-black-800;
$color-os-nav-item-seperator: #050505;
$color-os-nav-active: $color-pf-black-800;
$color-os-nav-hover: lighten($color-os-nav-active, 8%);
$color-os-nav-border-left-active: $color-pf-blue-300;
$color-os-nav-icon: $color-pf-black-600;

// Patternfly defaults to 12px, which is too small in many cases
$font-size-base: 13px;
// Patternfly defaults to 40px, which leaves a lot more whitespace than we're used to
$grid-gutter-width: 30px;

$contextselector-pf-dropdown-toggle-padding-bottom: 20px;
$contextselector-pf-dropdown-toggle-padding-top: 20px;
$contextselector-pf-margin-left: 20px;
$contextselector-pf-bootstrap-select-width-desktop: 145px;
$contextselector-pf-bootstrap-select-width-mobile: $contextselector-pf-bootstrap-select-width-desktop;
