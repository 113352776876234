@charset "UTF-8";

.help-block {
  margin-bottom: 0px; }

.table {
  margin-bottom: 0px; }
  .table thead > tr > th {
    border-bottom: 0px; }
  .table th {
    text-transform: uppercase;
    padding-top: 0px !important; }
  .table tr:last-child {
    border-bottom: 1px solid #eee; }
  .table td {
    border-color: #eee !important;
    vertical-align: middle !important; }
    .table td input[type="radio"], .table td input[type="checkbox"] {
      margin-top: 0px;
      vertical-align: middle; }

.table-hover > tbody > tr:hover > td,
.table-hover > tbody > tr:hover > th {
  background-color: #EAF3FF; }

/**
 * Reusable non-layout effects styles used across many modules.
 */

.co-truncate {
  overflow: hidden;
  text-overflow: ellipsis; }

.co-nowrap {
  white-space: nowrap; }

/* Animations */
.co-an-fade-in-out {
  -webkit-transition-property: compact(opacity, false, false, false, false, false, false, false, false, false);
  -moz-transition-property: compact(opacity, false, false, false, false, false, false, false, false, false);
  -o-transition-property: compact(opacity, false, false, false, false, false, false, false, false, false);
  transition-property: compact(opacity, false, false, false, false, false, false, false, false, false);
  -webkit-transition-duration: compact(0.2s, false, false, false, false, false, false, false, false, false);
  -moz-transition-duration: compact(0.2s, false, false, false, false, false, false, false, false, false);
  -o-transition-duration: compact(0.2s, false, false, false, false, false, false, false, false, false);
  transition-duration: compact(0.2s, false, false, false, false, false, false, false, false, false);
  -webkit-transition-timing-function: compact(linear, false, false, false, false, false, false, false, false, false);
  -moz-transition-timing-function: compact(linear, false, false, false, false, false, false, false, false, false);
  -o-transition-timing-function: compact(linear, false, false, false, false, false, false, false, false, false);
  transition-timing-function: compact(linear, false, false, false, false, false, false, false, false, false); }
  .co-an-fade-in-out.ng-hide-add, .co-an-fade-in-out.ng-hide-remove {
    display: block !important; }
  .co-an-fade-in-out.ng-hide-add.ng-hide-add-active, .co-an-fade-in-out.ng-hide-remove {
    opacity: 0; }
  .co-an-fade-in-out.ng-hide-add, .co-an-fade-in-out.ng-hide-remove.ng-hide-remove-active {
    opacity: 1; }
  .co-an-fade-in-out.ng-enter, .co-an-fade-in-out.ng-move {
    opacity: 0; }
  .co-an-fade-in-out.ng-enter.ng-enter-active, .co-an-fade-in-out.ng-move.ng-move-active {
    opacity: 1; }
  .co-an-fade-in-out.ng-leave {
    opacity: 1; }
  .co-an-fade-in-out.ng-leave.ng-leave-active {
    opacity: 0; }

/* Reusable Modules */
input[type="number"] {
  max-width: 100px;
  -moz-appearance: textfield;
  -webkit-appearance: textfield;
  appearance: textfield; }
  input[type="number"]::-webkit-inner-spin-button, input[type="number"]::-webkit-outer-spin-button {
    margin: 0; }
input[type="number"] + .input-group-addon {
  width: auto;
}

input[type="radio"] {
  margin-right: 7px; }

.co-m-radio-desc {
  margin-left: 20px; }

.co-m-form-row,
.form-row {
  margin-bottom: 20px; }

.co-m-form-row:last-child {
  margin-bottom: 0px; }

.co-m-form-col {
  max-width: 600px; }

.co-m-modal-link {
  cursor: pointer;
  position: relative;
  padding-right: 9px;
  outline: none; }
  .co-m-modal-link:after {
    color: $co-gray;
    content: "\f105";
    font-family: "FontAwesome";
    pointer-events: none;
    position: absolute;
    right: 0; }
  .co-m-modal-link:hover:after {
    color: $co-gray-dark; }
  .co-m-modal-link:focus {
    outline: none;
  }

.co-m-invisible-input {
  border: 0;
  background: none;
  width: 100%;
  outline: 0; }
  .co-m-invisible-input:hover {
    cursor: copy; }

.co-m-primary-action {
  color: white;
  font-size: 90%;
  font-weight: 200;
  white-space: nowrap;
  cursor: pointer; }
  .co-m-primary-action:hover, .co-m-primary-action:visited {
    color: white; }

.co-m-pane__body-group {
  padding: 0 0 30px 0; }

.co-m-table-grid .row {
  line-height: normal;
  margin: 0; }

.co-m-table-grid .row,
.co-m-table-grid [class*='col-'] {
  vertical-align: middle; }

.co-m-table-grid .co-m-table-grid {
  padding-left: 20px; }

.co-m-table-grid__body {
  min-height: 50px;
  position: relative;
  width: 100%; }

.co-m-table-grid .co-m-table-grid__body .row {
  padding: 10px 0; }

.co-m-table-grid--bordered .co-m-table-grid__head,
.co-m-table-grid--bordered .co-m-table-grid__body .row {
  border-bottom: solid 1px #eee; }

.co-m-table-grid__head {
  font-size: 12px;
  padding: 10px 0; }
.co-m-table-grid__head a {
  cursor: pointer; }

.co-m-loader,
.co-m-inline-loader {
  min-width: 18px; }

.co-m-loader {
  display: block;
  position: absolute;
  left: 50%;
  top: 50%;
  margin: -11px 0 0 -13px; }

.co-m-inline-loader {
  display: inline-block;
  cursor: default; }
  .co-m-inline-loader:hover {
    text-decoration: none; }

.co-m-loader-dot__one,
.co-m-loader-dot__two,
.co-m-loader-dot__three {
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  -o-border-radius: 3px;
  border-radius: 3px;
  animation-fill-mode: both;
  -webkit-animation-fill-mode: both;
  -moz-animation-fill-mode: both;
  -ms-animation-fill-mode: both;
  -o-animation-fill-mode: both;
  animation-name: bouncedelay;
  animation-duration: 1s;
  animation-timing-function: ease-in-out;
  animation-delay: 0;
  animation-direction: normal;
  animation-iteration-count: infinite;
  animation-fill-mode: forwards;
  animation-play-state: running;
  -webkit-animation-name: bouncedelay;
  -webkit-animation-duration: 1s;
  -webkit-animation-timing-function: ease-in-out;
  -webkit-animation-delay: 0;
  -webkit-animation-direction: normal;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-fill-mode: forwards;
  -webkit-animation-play-state: running;
  -moz-animation-name: bouncedelay;
  -moz-animation-duration: 1s;
  -moz-animation-timing-function: ease-in-out;
  -moz-animation-delay: 0;
  -moz-animation-direction: normal;
  -moz-animation-iteration-count: infinite;
  -moz-animation-fill-mode: forwards;
  -moz-animation-play-state: running;
  display: inline-block;
  height: 6px;
  width: 6px;
  background: #419eda;
  border-radius: 100%;
  display: inline-block; }

.co-m-loader-dot__one {
  animation-delay: -0.32s;
  -webkit-animation-delay: -0.32s;
  -moz-animation-delay: -0.32s;
  -ms-animation-delay: -0.32s;
  -o-animation-delay: -0.32s; }

.co-m-loader-dot__two {
  animation-delay: -0.16s;
  -webkit-animation-delay: -0.16s;
  -moz-animation-delay: -0.16s;
  -ms-animation-delay: -0.16s;
  -o-animation-delay: -0.16s; }

@-webkit-keyframes bouncedelay {
  0%, 80%, 100% {
    -webkit-transform: scale(0.25, 0.25);
    -moz-transform: scale(0.25, 0.25);
    -ms-transform: scale(0.25, 0.25);
    -o-transform: scale(0.25, 0.25);
    transform: scale(0.25, 0.25); }
  40% {
    -webkit-transform: scale(1, 1);
    -moz-transform: scale(1, 1);
    -ms-transform: scale(1, 1);
    -o-transform: scale(1, 1);
    transform: scale(1, 1); } }

@-moz-keyframes bouncedelay {
  0%, 80%, 100% {
    -webkit-transform: scale(0.25, 0.25);
    -moz-transform: scale(0.25, 0.25);
    -ms-transform: scale(0.25, 0.25);
    -o-transform: scale(0.25, 0.25);
    transform: scale(0.25, 0.25); }
  40% {
    -webkit-transform: scale(1, 1);
    -moz-transform: scale(1, 1);
    -ms-transform: scale(1, 1);
    -o-transform: scale(1, 1);
    transform: scale(1, 1); } }

@-ms-keyframes bouncedelay {
  0%, 80%, 100% {
    -webkit-transform: scale(0.25, 0.25);
    -moz-transform: scale(0.25, 0.25);
    -ms-transform: scale(0.25, 0.25);
    -o-transform: scale(0.25, 0.25);
    transform: scale(0.25, 0.25); }
  40% {
    -webkit-transform: scale(1, 1);
    -moz-transform: scale(1, 1);
    -ms-transform: scale(1, 1);
    -o-transform: scale(1, 1);
    transform: scale(1, 1); } }

@keyframes bouncedelay {
  0%, 80%, 100% {
    -webkit-transform: scale(0.25, 0.25);
    -moz-transform: scale(0.25, 0.25);
    -ms-transform: scale(0.25, 0.25);
    -o-transform: scale(0.25, 0.25);
    transform: scale(0.25, 0.25); }
  40% {
    -webkit-transform: scale(1, 1);
    -moz-transform: scale(1, 1);
    -ms-transform: scale(1, 1);
    -o-transform: scale(1, 1);
    transform: scale(1, 1); } }

.co-m-cog {
  position: relative;
  color: #999;
  display: inline-block;
  font-size: 14px; }
  .co-m-cog:hover {
    cursor: pointer; }
  .co-m-cog.open .co-m-cog__icon {
    color: #2276ad; }

.co-m-cog--disabled {
  pointer-events: none; }

.co-m-cog-wrapper {
  display: inline-block; }

.co-m-cog__icon--disabled {
  color: #ddd; }
