$cluster-updates-border: #ccc;
$cluster-updates-operator-icon-width: 25px;
$cluster-updates-green: #2ec98e;
$cluster-updates-width: 800px;

.co-cluster-updates-warning {
  max-width: $cluster-updates-width;
}

.co-cluster-updates {
  border: 1px solid $cluster-updates-border;
  max-width: $cluster-updates-width;
}

.co-cluster-updates__operator-icon--complete,
.co-cluster-updates__operator-icon--up-to-date,
.co-cluster-updates__operator-ts--completed,
.co-cluster-updates--update-available,
.co-cl-operator--downloaded,
.co-cl-operator--up-to-date {
  color: $cluster-updates-green;
}

.co-cluster-updates__operator-icon--needsattention,
.co-cluster-updates--needs-attention,
.co-cl-operator--pending {
  color: $color-orange-warning;
}

.co-cluster-updates__operator-icon--failed,
.co-cluster-updates__operator-ts--failed,
.co-cluster-updates__operator-ts--backoff,
.co-cluster-updates--failed {
  color: $color-red-error;
}

.co-cluster-updates__operator-icon--pending,
.co-cluster-updates__operator-ts--notstarted,
.co-cl-operator--pending {
  color: $color-grey-text;
}

.co-cluster-updates__operator-icon--updating,
.co-cluster-updates__operator-icon--paused,
.co-cluster-updates--updating,
.co-cluster-updates__operator-ts--running,
.co-cl-operator-spinner--downloading,
.co-cl-operator-spinner--rebooting {
  color: $color-highlight-blue;
}

.co-cluster-updates__text-icon {
  margin-right: 4px;
}

.co-cluster-updates__action-button {
  display: block;
  margin-top: 5px;
}

.co-cluster-updates__component {
  border-bottom: 1px solid $cluster-updates-border;
  margin-bottom: -1px;
  padding: 20px;
}

.co-cluster-updates__heading {
  font-size: 13px;
  justify-content: space-between;
  line-height: 13px;
}

.co-cluster-updates__heading--name-wrapper {
  min-width: 40%;
  padding-right: 10px;
}

.co-cluster-updates__heading--name {
  font-size: 16px;
  font-weight: 600;
}

.co-cluster-updates__heading--version {
  color: $color-grey-text;
  margin-left: 0.75em;
}

.co-cluster-updates__heading--updates {
  flex: 1 0 0;
}

.co-cluster-updates__toggle {
  // flex: 0 0 1;
}

.co-cluster-updates__details {
  display: flex;
  flex-direction: row;
  font-size: 16px;
  margin: 18px 0 0 0;
}

.co-cluster-updates__detail {
  flex: 2 1 auto;
  padding: 0 5px;
}

.co-cluster-updates__operator {
  display: flex;
  flex-direction: row;
  margin: 30px 0 10px 0;
}

.co-cluster-updates__operator-component {
  flex: 1 1 auto;
}

.co-cluster-updates__operator-step {
  display: flex;
  flex-direction: row;
  margin-bottom: 5px;

  &:last-of-type {
    margin-bottom: 0;
  }
}

.co-cluster-updates__operator-details {
  padding-left: 25px;
  button {
    padding-top: 0;
  }
}

.co-cluster-updates__operator-show-details {
  padding: 0 0 20px 0;
}

.co-cluster-updates__operator-logs {
  margin-bottom: 20px;
}

.co-cluster-updates__operator-header {
  font-weight: 600;
}

.co-cluster-updates__operator-icon {
  min-width: 22px;
  width: $cluster-updates-operator-icon-width;
}

.co-cluster-updates__operator-text {
  flex-grow: 1;
}

.co-cluster-updates__operator-button {
  margin-bottom: 15px;
  padding: 7px 16px 4px;
}

.co-cluster-updates__operator-ts-error-msg {
  border-top: 1px solid $color-red-error;
  color: $color-red-error;
  font-size: 11px;
  margin: 5px 0 0 $cluster-updates-operator-icon-width;
  padding-top: 5px;
}

.co-cluster-updates__operator-ts-error-msg-link {
  margin-left: $cluster-updates-operator-icon-width;
  font-size: 12px;
}

.co-cluster-updates__operator-ts-component {
  margin-top: 10px;
}

.co-cluster-updates__operator-ts-step {
  display: flex;
  flex-direction: row;
}

.co-cluster-updates__message-box {
  margin: 15px 0 0 5px;
  padding: 10px;
  overflow: auto;
}

.co-cluster-updates__message-box--failed {
  background-color: rgba(214, 68, 86, 0.1);
  border: 1px solid $color-red-error;
  color: $color-red-error;
}

.co-cluster-updates__message-box--warning {
  background-color: rgba(252, 166, 87, 1);
  border: 1px solid $color-orange-warning;
  color: $color-orange-warning;
}

.co-cluster-updates__operator-subheader {
  font-weight: 600;
}

.co-cluster-channel-dropdown {
  height: 120px;
  & > .dropdown {
    & > .btn--dropdown {
      width: 250px;
    }
    & > .dropdown-menu {
      width: 250px;
    }
  }
}

.co-cluster-updates__sec-appversion-ts {
  border-left: 1px solid $cluster-updates-border;
  border-image: linear-gradient(#eee, #ccc, #eee);
  border-image-slice: 1;
  margin-left: 22px;
}

.co-cluster-updates__operator-text--running {
  border-bottom: 1px solid $cluster-updates-border;
  padding-bottom: 5px;
}

.co-cluster-updates__appversion-ts {
  padding-right: 0;
}
