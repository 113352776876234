.ldap-group {
  padding-top: 10px;
}

.certs-info-cert {
  padding-bottom: 5px;
  .co-resource-link {
    .co-m-resource-icon {
      margin: 0 4px 0 0;
    }
  }
}

.alert-manager-list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.alert-manager-row {
  flex: 0 1 50%;
}
