.co-disabled {
  position: relative;
}

.co-disabled::before {
  background-color: #fff;
  bottom: 0;
  content: '';
  cursor: not-allowed;
  left: 0;
  opacity: 0.4;
  overflow: hidden;
  position: absolute;
  right: 0;
  top: 0;
  user-select: none;
  z-index: 10;
}
