.cluster-picker {
  background-color: $color-os-nav-active;
  border-radius: 1px;
  color: $color-os-nav-title;
  margin: 7px 10px;
  padding: 2px 0 2px 10px;

  .dropdown {
    .dropdown__not-btn {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      padding-right: 20px;
      .caret {
        position: absolute;
        right: 9px;
        top: 6px;
      }
    }
    ul.dropdown-menu {
      box-shadow: 0 2px 4px rgba(0,0,0,0.175);
      margin-left: -10px;
      margin-top: 2px;
      min-width: 200px;
      @media(max-width: $grid-float-breakpoint-max) {
        width: 200px;
      }
      > li {
        &.active > a {
          background-color: $color-os-nav-active !important;
          border-color: transparent !important;
        }
        > a {
          @media(max-width: $grid-float-breakpoint-max) {
            overflow-wrap: break-word;
            white-space: normal;
            width: 100%;
            word-break: break-word;
          }
          &:hover {
            background-color: $color-os-nav-hover;
          }
        }
      }
    }
  }
}

.cluster-picker:hover {
  background-color: $color-os-nav-active;
}
