.rbac-rules-list {
  margin: 30px 0;
}

.rbac-rule {
  &__actions {
    align-items: baseline;
    display: flex;
  }
  &__cog {
    margin-right: 5px;
  }
}

.rbac-rule-resource {
  align-items: baseline;
  display: inline-flex;
  margin-right: 20px;
  min-width: 0; // so co-break-word works on .rbac-rule-resource__label
  &__label {
    @include co-break-word;
    min-width: 0; // so co-break-word works
  }
  .co-m-resource-icon {
    flex-shrink: 0;
  }
}

.rbac-rule-resources {
  display: flex;
  flex-wrap: wrap;
}

.rbac-rule-row:not(:last-of-type) {
  padding-bottom: 5px;
}

.resource-separator {
  margin-top: 10px;
  margin-bottom: 10px;
}

.edit-rule {
  .rule-row {
    margin-top: 30px;
  }
  .help-text {
    color: $color-text-secondary;
    font-weight: 400;
    position: relative;
    top: -3px;
    left: 20px;
  }
  .checkbox-label {
    margin-bottom: 5px;
    font-weight: 400;
  }

  .rbac-minor {
    margin-top: 10px;
    margin-bottom: 20px;
    color: #ccc;
  }
  .rbac-major {
    color: #ccc;
    margin: 30px 0;
  }
  .newspaper-columns {
    columns: 3 auto;
    margin-top: 20px;
  }
  .text-input {
    max-width: 500px;
    margin-top: 20px
  }
}

.rbac-edit-binding {

  .alert {
    margin-top: 20px;
  }

  .btn--dropdown {
    max-width: 400px;
    width: 100%;
  }

  .btn-group {
    width: 100%;
  }

  .form-control {
    max-width: 400px;
  }
  .dropdown {
    max-width: 400px;
  }

  @media (min-width: $grid-float-breakpoint) {
    .dropdown-menu {
      min-width: 400px;
    }
  }

  .rbac-edit-binding__input-label {
    margin-bottom: 5px;
  }

  .radio-item {
    margin: 10px 0;
    .co-m-radio-desc {
      margin-left: 19px;
    }
  }

  .separator {
    border-top: solid 1px #eee;
    margin: 30px 0;
  }
}
