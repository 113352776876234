.co-node-ip {
  margin: 0;
  padding: 0;
  line-height: 14px;
  p {
    margin: 0;
    padding: 5px 0;
  }
}

.co-ip-type {
  color: #999;
}

.co-ip-addr {
  color: black;
}
