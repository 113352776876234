.co-sysevent-icon {
  background-color: #fff;
  border: solid 3px $color-grey-border;
  border-radius: 50%;
  color: #fff;
  position: relative;
  z-index: 10;
  height: 18px;
  width: 18px;
}


.co-sysevent--error {
  $failure-red: #d64456;

  .co-sysevent-icon,
  .co-sysevent__box {
    border-color: $failure-red;
  }
  .co-sysevent__icon-line {
    background-color: $failure-red;
  }
}
