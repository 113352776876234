.value-from {
  padding-top: 5px;
  width: 100%;
  @media(min-width: $screen-md-min) {
    width: 50%;
  }

  .btn--dropdown {
    width: 100%;
  }

  .dropdown-menu {
    min-width: 250px;
    width: 100%;
  }
}
@media (max-width: $screen-xs-max) {
  .value-from__menu {
    left: auto;
    right: 0;
  }
}
